<template>
  <Head :title="__('frontend.password_reset.title')" />

  <Container>
    <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
      <div class="flex min-h-full justify-center p-4 text-center items-center">
        <div class="relative transform overflow-hidden rounded-lg bg-white p-6 text-left max-w-[500px]">
          <img
            src="@frontend/assets/logo.svg"
            class="w-full lg:mx-auto p-6"
          >

          <form
            class="space-y-3 min-w-[300px]"
            @submit.prevent="form.post(route('frontend.password-reset.store'))"
          >
            <div class="w-full">
              <label
                for="email"
                class="block text-xs text-gray-300"
              >
                {{ __('frontend.password_reset.email') }}
              </label>
              <div class="mt-0.5">
                <input
                  v-model="form.email"
                  type="email"
                  name="email"
                  class="block w-full rounded-md border-0 py-2 pl-3 text-black ring-1 ring-inset focus:ring-2 focus:ring-inset"
                  :class="$page.props.errors.email ? 'border-red ring-red text-red placeholder-red focus:outline-none focus:ring-red focus:border-red' : 'focus:ring-blue ring-gray-300 focus:border-gray-300'"
                >

                <p
                  v-if="$page.props.errors.email"
                  class="mt-1 text-sm text-red"
                  v-text="$page.props.errors.email"
                />
              </div>
            </div>

            <div class="w-full">
              <button
                type="submit"
                class="w-full block rounded-md bg-orange text-white py-3 text-center hover:bg-orange/90"
                :disabled="form.processing"
              >
                {{ __('frontend.password_reset.send_reset_link') }}
                <i
                  class="fas fa-chevron-right ml-2 inline w-2 -mt-0.5"
                  aria-hidden="true"
                />
              </button>
            </div>

            <Link
              :href="route('frontend.login')"
              class="text-orange underline hover:no-underline block text-center text-sm"
            >
              {{ __('frontend.password_reset.back') }}
            </Link>
          </form>
        </div>
      </div>
    </div>
  </Container>
</template>

<script setup>
import {Head, useForm} from '@inertiajs/vue3';
import Container from '@frontend/js/Components/Container.vue';
import {defineOptions} from 'vue';
import Auth from '../../../Layouts/Auth.vue';

defineOptions({
  layout: Auth,
});

const form = useForm({
  email: null,
});
</script>
