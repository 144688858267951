<template>
  <Head :title="__('frontend.password_reset.title')" />

  <Container>
    <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
      <div class="flex min-h-full justify-center p-4 text-center items-center">
        <div class="relative transform overflow-hidden rounded-lg bg-white p-6 text-left max-w-[500px]">
          <img
            src="@frontend/assets/logo.svg"
            class="w-full lg:mx-auto p-6"
          >

          <form
            class="space-y-3 min-w-[300px]"
            @submit.prevent="form.patch(route('frontend.password-reset.update', token))"
          >
            <p class="text-gray-300 text-sm pb-2">
              {{ __('frontend.password_reset.explanation') }}
            </p>

            <div class="w-full">
              <label
                for="email"
                class="block text-xs text-gray-300"
              >
                {{ __('frontend.password_reset.new_password') }}
              </label>
              <div class="mt-0.5 mb-2">
                <input
                  v-model="form.password"
                  type="password"
                  name="password"
                  class="block w-full rounded-md border-0 py-2 pl-3 text-black ring-1 ring-inset focus:ring-2 focus:ring-inset"
                  :class="$page.props.errors.password ? 'border-red ring-red text-red placeholder-red focus:outline-none focus:ring-red focus:border-red' : 'focus:ring-blue ring-gray-300 focus:border-gray-300'"
                >

                <p
                  v-if="$page.props.errors.password"
                  class="mt-1 text-sm text-red"
                  v-text="$page.props.errors.password"
                />
              </div>

              <label
                for="email"
                class="block text-xs text-gray-300"
              >
                {{ __('frontend.password_reset.new_password_repeat') }}
              </label>
              <div class="mt-0.5">
                <input
                  v-model="form.password_confirmation"
                  type="password"
                  name="password_confirmation"
                  class="block w-full rounded-md border-0 py-2 pl-3 text-black ring-1 ring-inset focus:ring-2 focus:ring-inset "
                  :class="$page.props.errors.password_confirmation ? 'border-red ring-red text-red placeholder-red focus:outline-none focus:ring-red focus:border-red' : 'focus:ring-blue ring-gray-300 focus:border-gray-300'"
                >

                <p
                  v-if="$page.props.errors.password_confirmation"
                  class="mt-1 text-sm text-red"
                  v-text="$page.props.errors.password_confirmation"
                />
              </div>
            </div>

            <div class="w-full">
              <button
                type="submit"
                class="w-full block rounded-md bg-orange text-white py-3 text-center hover:bg-orange/90"
                :class="{
                  'cursor-not-allowed disabled bg-gray-400 text-gray-800': !canSubmit(),
                }"
                :disabled="!canSubmit()"
              >
                {{ __('frontend.password_reset.submit_button') }}
                <i
                  class="fas fa-chevron-right ml-2 inline w-2 -mt-0.5"
                  aria-hidden="true"
                />
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </Container>
</template>

<script setup>
import {Head, useForm} from '@inertiajs/vue3';
import {defineProps, defineOptions} from 'vue';
import Container from '@frontend/js/Components/Container.vue';
import Auth from '../../../Layouts/Auth.vue';

defineOptions({
  layout: Auth,
});

const props = defineProps({
  token: {
    type: String,
    required: true,
  },

  email: {
    type: String,
    required: true,
  },
});

const form = useForm({
  token: props.token,
  email: props.email,
  password: null,
  password_confirmation: null,
});

const canSubmit = function() {
  if (form.processing) {
    return false;
  }

  if (!form.password || !form.password_confirmation) {
    return false;
  }

  return form.password === form.password_confirmation;
};
</script>
