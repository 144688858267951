<template>
  <i18n-t v-bind="attrs">
    <template
      v-for="(_, slotName) in $slots"
      #[slotName]
    >
      <slot :name="slotName" />
    </template>
  </i18n-t>
</template>

<script setup>
import {useAttrs} from 'vue';
import {useStore} from 'vuex';

const attrs = useAttrs();
const store = useStore();

store.dispatch('translations/addUsed', {key: attrs.keypath});
</script>
