<template>
  <div class="mt-8 md:mt-4 border-gray-200 border rounded-md overflow-hidden text-sm">
    <div class="font-bold text-base px-4 py-2 bg-gray-100 text-black break-words">
      {{ __('frontend.delivery_planner.sidebar.title') }}
    </div>

    <div
      v-if="preStop.reference"
      class="border-t border-gray-200 flex flex-wrap px-4 py-2"
    >
      <div class="w-1/3 pr-2">
        {{ __('frontend.delivery_planner.sidebar.reference') }}
      </div>

      <div class="w-2/3 ">
        {{ preStop.reference }}
      </div>
    </div>

    <div
      v-if="preStop.recipient_name"
      class="border-t border-gray-200 flex flex-wrap px-4 py-2"
    >
      <div class="w-1/3 pr-2 break-words">
        {{ __('frontend.delivery_planner.sidebar.recipient_name') }}
      </div>

      <div class="w-2/3 break-words">
        {{ preStop.recipient_name }}
      </div>
    </div>

    <div class="border-t border-gray-200 flex flex-wrap px-4 py-2">
      <div class="w-1/3 pr-2 break-words">
        {{ __('frontend.delivery_planner.sidebar.address') }}
      </div>

      <div class="w-2/3 break-words">
        {{ formattedAddress }}

        <div
          v-if="formattedRemarks"
          class="mt-1"
        >
          <i>{{ formattedRemarks }}</i>
        </div>

        <button
          v-if="settings.allowAddressEdit"
          type="button"
          class="underline text-blue-600 hover:no-underline block"
          @click="showAddressModal = true"
        >
          {{ __('frontend.delivery_planner.sidebar.edit') }}
        </button>
      </div>
    </div>

    <div
      v-if="preStop.external_reference"
      class="border-t border-gray-200 flex flex-wrap px-4 py-2"
    >
      <div class="w-1/3 pr-4 break-words">
        {{ __('frontend.delivery_planner.sidebar.external_reference') }}
      </div>

      <div class="w-2/3 break-words">
        {{ preStop.external_reference }}
      </div>
    </div>

    <div
      v-if="preStop.shipment_lines"
      class="border-t border-gray-200 flex flex-wrap px-4 py-2"
    >
      <div class="w-1/3 pr-4 break-words">
        {{ __('frontend.delivery_planner.sidebar.shipment_lines') }}
      </div>

      <div
        class="w-2/3 break-words cursor-pointer"
        @click="openShipmentLineModal(
          __('frontend.delivery_planner.sidebar.shipment_lines_modal_title'),
          preStop.shipment_lines
        )"
      >
        <template
          v-for="i of 3"
          :key="i"
        >
          <div v-if="preStop.shipment_lines[i - 1]">
            {{ preStop.shipment_lines[i - 1].quantity }}x {{ preStop.shipment_lines[i - 1].description }}
          </div>
        </template>
        <div v-if="preStop.shipment_lines.length > 3">
          ... <i class="far fa-info-circle" />
        </div>
      </div>
    </div>

    <div
      v-if="preStop.exchange_lines"
      class="border-t border-gray-200 flex flex-wrap px-4 py-2"
    >
      <div class="w-1/3 pr-4 break-words">
        {{ __('frontend.delivery_planner.sidebar.exchange_lines') }}
      </div>

      <div
        class="w-2/3 break-words cursor-pointer"
        @click="openShipmentLineModal(
          __('frontend.delivery_planner.sidebar.exchange_lines_modal_title'),
          preStop.exchange_lines
        )"
      >
        <template
          v-for="i of 3"
          :key="i"
        >
          <div v-if="preStop.exchange_lines[i - 1]">
            {{ preStop.exchange_lines[i - 1].quantity }}x {{ preStop.exchange_lines[i - 1].description }}
          </div>
        </template>
        <div v-if="preStop.exchange_lines.length > 3">
          ... <i class="far fa-info-circle" />
        </div>
      </div>
    </div>

    <div
      v-if="preStop.total_weight > 0"
      class="border-t border-gray-200 flex flex-wrap px-4 py-2"
    >
      <div class="w-1/3 pr-4 break-words">
        {{ __('frontend.delivery_planner.sidebar.weight') }}
      </div>

      <div class="w-2/3 break-words">
        {{ preStop.total_weight_formatted }} kg
      </div>
    </div>

    <div
      v-if="settings.smsIsEnabled"
      class="border-t border-gray-200 flex flex-wrap px-4 py-2"
    >
      <div class="w-1/3 pr-4 break-words">
        <input
          v-model="form.notification_phone_enabled"
          type="checkbox"
        >
        {{ __('frontend.delivery_planner.sidebar.sms_notification') }}
      </div>

      <div class="w-2/3 break-words">
        {{ form.notification_phone }}

        <button
          type="button"
          class="underline text-blue-600 hover:no-underline block"
          @click="showNotificationPhoneModal = true"
        >
          {{ __('frontend.delivery_planner.sidebar.edit') }}
        </button>
      </div>
    </div>

    <div
      v-if="settings.emailIsEnabled"
      class="border-t border-gray-200 flex flex-wrap px-4 py-2"
    >
      <div class="w-1/3 pr-4 break-words">
        <input
          v-model="form.notification_email_enabled"
          type="checkbox"
        >
        {{ __('frontend.delivery_planner.sidebar.email_notification') }}
      </div>

      <div class="w-2/3 break-words">
        {{ form.notification_email }}

        <button
          type="button"
          class="underline text-blue-600 hover:no-underline block"
          @click="showNotificationEmailModal = true"
        >
          {{ __('frontend.delivery_planner.sidebar.edit') }}
        </button>
      </div>
    </div>

    <div
      v-if="formattedEta"
      class="border-t border-gray-200 flex flex-wrap px-4 py-2"
    >
      <div class="w-1/3 pr-4 break-words">
        {{ __(`frontend.delivery_planner.eta.${preStop.translation_key}`) }}
      </div>

      <!-- eslint-disable vue/no-v-html -->
      <div
        class="w-2/3 break-words"
        v-html="formattedEta"
      />
      <!-- eslint-enable -->
    </div>

    <div
      v-if="costs.cash_on_delivery_amount_left_to_pay.isGreaterThan('0.00')"
      class="border-t border-gray-200 flex flex-wrap px-4 py-2"
    >
      <div class="w-1/3 pr-4 break-words">
        {{ __('frontend.delivery_planner.sidebar.cash_on_delivery_amount') }}
      </div>

      <div class="w-2/3 break-words">
        {{ formatMoney(costs.cash_on_delivery_amount_left_to_pay) }}
      </div>
    </div>

    <div
      v-if="costs.service_amount_left_to_pay.isGreaterThan('0.00')"
      class="border-t border-gray-200 flex flex-wrap px-4 py-2"
    >
      <div class="w-1/3 pr-4 break-words">
        {{ __('frontend.delivery_planner.sidebar.service_amount') }}
      </div>

      <div class="w-2/3 break-words">
        {{ formatMoney(costs.service_amount_left_to_pay) }}
      </div>
    </div>

    <div
      v-if="formattedHousingType"
      class="border-t border-gray-200 flex flex-wrap px-4 py-2"
    >
      <div class="w-1/3 pr-4 break-words">
        {{ __('frontend.delivery_planner.sidebar.housing_type') }}
      </div>

      <!-- eslint-disable vue/no-v-html -->
      <div
        class="w-2/3 break-words"
        v-html="formattedHousingType"
      />
      <!-- eslint-enable -->
    </div>

    <div
      v-if="formattedDeliveryServices.length"
      class="border-t border-gray-200 flex flex-wrap px-4 py-2"
    >
      <div class="w-1/3 pr-4 break-words">
        {{ __('frontend.delivery_planner.sidebar.services') }}
      </div>

      <div class="w-2/3 break-words">
        <!-- eslint-disable vue/no-v-html -->
        <div
          v-for="(formattedDeliveryService, index) of formattedDeliveryServices"
          :key="index"
          v-html="formattedDeliveryService"
        />
        <!-- eslint-enable -->
      </div>
    </div>
  </div>

  <button
    v-if="settings.helpButton"
    type="button"
    class="bg-blue-600 text-white py-1.5 px-3 text-xs inline-block mt-2 rounded-md ml-auto hover:bg-blue-600/70"
    @click="openHelpModal"
  >
    <i class="fas fa-question-circle" /> {{ settings.helpButton }}
  </button>

  <AddressModal
    v-if="showAddressModal"
    @close="showAddressModal = false"
  />
  <ShipmentLinesModal
    v-if="showShipmentLineModal"
    :title="shipmentLineModalTitle"
    :lines="shipmentLineModalLines"
    @close="showShipmentLineModal = false"
  />
  <NotificationPhoneModal
    v-if="showNotificationPhoneModal"
    @close="showNotificationPhoneModal = false"
  />
  <NotificationEmailModal
    v-if="showNotificationEmailModal"
    @close="showNotificationEmailModal = false"
  />
</template>

<script setup>
import {computed, defineEmits, inject, ref} from 'vue';
import {__, formatMoney} from '@global/js/helpers';
import ShipmentLinesModal from '@frontend/js/Components/DeliveryPlanner/Modals/ShipmentLinesModal.vue';
import NotificationEmailModal from '@frontend/js/Components/DeliveryPlanner/Modals/NotificationEmailModal.vue';
import NotificationPhoneModal from '@frontend/js/Components/DeliveryPlanner/Modals/NotificationPhoneModal.vue';
import AddressModal from '@frontend/js/Components/DeliveryPlanner/Modals/AddressModal.vue';
import Decimal from '@agrora/decimal';
import moment from 'moment/moment';

const emit = defineEmits(['help']);

const preStop = inject('preStop');
const form = inject('form');
const costs = inject('costs');
const settings = inject('settings');

const showShipmentLineModal = ref(false);
const showAddressModal = ref(false);
const showNotificationPhoneModal = ref(false);
const showNotificationEmailModal = ref(false);
const shipmentLineModalTitle = ref('');
const shipmentLineModalLines = ref([]);

const formattedAddress = computed(() => {
  let streetWithNumberAndAddition = [form.street, form.house_no, form.address_addition]
    .filter(v => v?.length)
    .join(' ');

  return [streetWithNumberAndAddition, form.postcode, form.city]
    .filter(v => v?.length)
    .join(', ');
});

const formattedRemarks = computed(() => {
  if (!form.remarks) {
    return null;
  }

  if (form.remarks.length <= 50) {
    return form.remarks;
  }

  return form.remarks.slice(0, 50) + '...';
});

const formattedHousingType = computed(() => {
  if (!form.housing_type) {
    return null;
  }

  let text = form.housing_type.name;

  if (form.entrance_floor_option) {
    text += `, ${form.entrance_floor_option.name}`;
  } else if (form.select_other_entrance_floor && form.custom_entrance_floor !== '') {
    text += `, ${__('frontend.delivery_planner.sidebar.entrance_floor')}: ${form.custom_entrance_floor}`;
  } else if (preStop.floor > 0) {
    text += `, ${__('frontend.delivery_planner.sidebar.entrance_floor')}: ${preStop.floor}`;
  }

  if (form.delivery_floor_option) {
    text += `, ${form.delivery_floor_option.name}`;
  } else if (form.select_other_delivery_floor && form.custom_delivery_floor !== '') {
    text += `, ${__('frontend.delivery_planner.sidebar.delivery_floor')}: ${form.custom_delivery_floor}`;
  } else if (preStop.delivery_floor > 0) {
    text += `, ${__('frontend.delivery_planner.sidebar.entrance_floor')}: ${preStop.delivery_floor}`;
  }

  if (!costs.housing_type_floor_options_costs.isZero() || !costs.floor_addition_weight_costs.isZero()) {
    const floorCosts = costs.housing_type_floor_options_costs
      .add(costs.floor_addition_weight_costs);

    text += ` (<strong>+ ${formatMoney(floorCosts)}</strong>)`;
  }

  return text;
});

const formattedDeliveryServices = computed(() => {
  const formatted = [];

  if (form.skip_service_questions || form.service_questions.length === 0) {
    return formatted;
  }

  for (const serviceQuestionId of Object.keys(form.service_questions)) {
    const serviceQuestion = form.service_questions[serviceQuestionId];

    if (serviceQuestion.value) {
      const amount = serviceQuestion.serviceQuestion.type === 'quantity'
        ? parseInt(serviceQuestion.value)
        : 1;
      const price = Decimal.fromString(serviceQuestion.serviceQuestion.price)
        .multiply(amount);

      if (serviceQuestion.serviceQuestion.type === 'checkbox') {
        formatted.push(`${serviceQuestion.serviceQuestion.question} (<strong>+ ${formatMoney(price)}</strong>)`);
      } else if (serviceQuestion.serviceQuestion.type === 'quantity') {
        formatted.push(`${amount}x ${serviceQuestion.serviceQuestion.question} (<strong>+ ${formatMoney(price)}</strong>)`);
      }
    }
  }

  return formatted;
});

const formattedEta = computed(() => {
  if (form.eta_from && form.eta_to) {
    let day = moment(form.eta_from).format('dddd D MMMM');
    day = day.charAt(0).toUpperCase() + day.slice(1);

    const from = moment(form.eta_from).format('HH:mm');
    const to = moment(form.eta_to).format('HH:mm');

    let formatted = `${day}, ${from} - ${to}`;
    if (from === '00:00' && to === '00:00') {
      formatted = day;
    }

    if (!costs.date_costs.isZero()) {
      return `${formatted} (<strong>+ ${formatMoney(costs.date_costs)}</strong>)`;
    }

    return formatted;
  }

  return null;
});

const openHelpModal = () => {
  emit('help');
};

const openShipmentLineModal = (title, lines) => {
  showShipmentLineModal.value = true;
  shipmentLineModalTitle.value = title;
  shipmentLineModalLines.value = lines;
};
</script>
