<template>
  <Head :title="__('frontend.index.title')" />

  <Container>
    <div class="bg-white p-8 rounded-md">
      <HeaderLogo classes="w-full lg:w-4/5 lg:mx-auto" />

      <div v-if="error || $page.props.flash.error">
        <div class="bg-red-100 mt-6 p-4 rounded-md text-red-800">
          {{ error ?? $page.props.flash.error }}
        </div>
      </div>

      <form
        class="mt-6 space-y-3"
        autocomplete="off"
        @submit.prevent="formSubmit"
      >
        <div>
          <label
            for="location"
            class="block text-xs text-gray-300"
          >{{ __('frontend.index.search_form.country') }}</label>
          <select
            id="location"
            v-model="form.country"
            name="country"
            class="mt-0.5 block w-full rounded-md border-0 py-2 pl-3 pr-10 text-black ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue"
          >
            <option
              v-for="(name, code) of countries"
              :key="code"
              :value="code"
            >
              {{ name }}
            </option>
          </select>
        </div>

        <div class="flex space-x-4">
          <div class="w-3/5">
            <label
              for="postcode"
              class="block text-xs text-gray-300"
            >{{ __('frontend.index.search_form.postcode') }}</label>
            <div class="mt-0.5">
              <input
                id="postcode"
                v-model="form.postcode"
                type="text"
                name="postcode"
                class="block w-full rounded-md border-0 py-2 pl-3 text-black ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-blue"
                placeholder=""
              >
            </div>
          </div>

          <div class="w-2/5">
            <label
              for="house"
              class="block text-xs text-gray-300"
            >{{ __('frontend.index.search_form.house_number') }}</label>
            <div class="mt-0.5">
              <input
                id="house"
                v-model="form.house_no"
                type="text"
                name="house_no"
                class="block w-full rounded-md border-0 py-2 pl-3 text-black ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-blue"
                placeholder=""
              >
            </div>
          </div>
        </div>

        <span class="block w-full text-black text-xs text-center my-0">{{ __('frontend.index.search_form.or') }}</span>

        <div>
          <label
            for="reference"
            class="block text-xs text-gray-300"
          >{{ __('frontend.index.search_form.reference') }}</label>
          <div class="mt-0.5">
            <input
              id="reference"
              v-model="form.reference"
              type="text"
              name="reference"
              class="block w-full rounded-md border-0 py-2 pl-3 text-black ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-blue"
              placeholder=""
            >
          </div>
        </div>

        <button
          type="submit"
          class="w-full block rounded-md bg-orange text-white py-3 text-center hover:bg-orange/90"
          :class="{'!bg-custom-layout-color': $page.props.customLayout?.customColor}"
          :disabled="form.processing"
        >
          {{ __('frontend.index.search_form.button') }}
          <i
            class="fas fa-chevron-right ml-2 inline w-2 -mt-0.5"
            aria-hidden="true"
          />
        </button>
      </form>
    </div>
  </Container>

  <Slider
    :slider-images="sliderImages"
  />
</template>

<script setup>
import {Head, useForm, usePage} from '@inertiajs/vue3';
import {defineProps} from 'vue';
import HeaderLogo from '@frontend/js/Components/HeaderLogo.vue';
import Container from '../../Components/Container.vue';
import Slider from '../../Components/Slider.vue';

const props = defineProps({
  countries: {
    type: Object,
    required: true,
  },

  formValues: {
    type: Object,
    required: true,
  },

  error: {
    type: String,
    required: false,
    default: null,
  },

  sliderImages: {
    type: Array,
    required: true,
  },
});

const form = useForm({
  country: props.formValues.country ?? Object.keys(props.countries)[0],
  postcode: props.formValues.postcode ?? null,
  house_no: props.formValues.house_no ?? null,
  reference: props.formValues.reference ?? null,
});

const $page = usePage();

const formSubmit = () => {
  form.get(route('frontend.index'), {
    replace: true,
  });
};
</script>
