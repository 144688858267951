<template>
  <label
    :class="[disabled ? 'cursor-not-allowed' : 'cursor-pointer']"
    class="inline-flex"
  >
    <input
      v-if="serviceQuestion.type === 'checkbox'"
      :value="fixedValue ?? form.service_questions[serviceQuestion.id].value"
      :checked="checkedValue"
      type="checkbox"
      class="mr-1 inline-block disabled:text-slate-500 disabled:border-slate-200"
      :class="{
        'disabled:bg-slate-50': !fixedValue,
      }"
      :disabled="disabled"
      @input="updateServiceQuestionValue(serviceQuestion.id, $event.target.checked)"
    >
    <input
      v-else-if="serviceQuestion.type === 'quantity'"
      :value="fixedValue ?? form.service_questions[serviceQuestion.id].value"
      type="number"
      min="0"
      class="w-12 py-0 px-1  mr-1 -mt-1 inline-block disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200"
      :disabled="disabled"
      @input="updateServiceQuestionValue(serviceQuestion.id, $event.target.value)"
    >
    {{ serviceQuestion.question }}

    <Tooltip
      v-if="serviceQuestion.question_help"
      class="ml-2"
    >
      <template #trigger>
        <i class="fas fa-question-circle text-blue-600" />
      </template>
      <template #content>
        <p>{{ serviceQuestion.question_help }}</p>
      </template>
    </Tooltip>
  </label>
  <span>{{ formatMoney(Decimal.fromString(serviceQuestion.price)) }}</span>
</template>

<script setup>
import {defineProps, inject} from 'vue';
import Tooltip from '@frontend/js/Components/Tooltip.vue';
import {formatMoney} from '@global/js/helpers';
import Decimal from '@agrora/decimal';

const props = defineProps({
  serviceQuestion: {
    type: Object,
    required: true,
  },

  disabled: {
    type: Boolean,
    required: true,
  },

  fixedValue: {
    type: [String, Number, Boolean],
    required: false,
    default: null,
  },
});

const form = inject('form');

const updateServiceQuestionValue = (serviceQuestionId, value) => {
  if (props.fixedValue) {
    return;
  }

  form.service_questions[serviceQuestionId].value = value;
};

const checkedValue = props.serviceQuestion.type === 'checkbox' && props.fixedValue ? (props.fixedValue === 1 || props.fixedValue === '1' || props.fixedValue === true) : null;

</script>
