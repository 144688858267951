<template>
  <Popover
    v-slot="{ open, close }"
    class="relative"
  >
    <PopoverButton
      @mouseover="(event) => hoverPopover(event, open)"
      @mouseleave="closePopover(close)"
    >
      <slot name="trigger" />
    </PopoverButton>

    <Transition
      enter-active-class="transition ease-out duration-200"
      enter-from-class="opacity-0 translate-y-1"
      enter-to-class="opacity-100 translate-y-0"
      leave-active-class="transition ease-in duration-150"
      leave-from-class="opacity-100 translate-y-0"
      leave-to-class="opacity-0 translate-y-1"
    >
      <PopoverPanel
        class="absolute left-1/2 z-10 mt-3 ml-0 w-auto min-w-[15rem] -translate-x-1/2 transform px-2 sm:px-0"
        @mouseover.prevent="popoverHover = true"
        @mouseleave.prevent="closePopover(close)"
      >
        <div
          class="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5"
        >
          <div class="relative grid gap-1 bg-white p-3">
            <slot name="content" />
          </div>
        </div>
      </PopoverPanel>
    </Transition>
  </Popover>
</template>

<script setup>
import {Popover, PopoverButton, PopoverPanel} from '@headlessui/vue';
import {ref} from 'vue';

const popoverHover = ref(false);
const popoverTimeout = ref();

const hoverPopover = (event, open) => {
  event.preventDefault();

  popoverHover.value = true;
  if (!open) {
    event.target.click();
  }
};

const closePopover = (close) => {
  popoverHover.value = false;
  if (popoverTimeout.value) {
    clearTimeout(popoverTimeout.value);
  }

  popoverTimeout.value = setTimeout(() => {
    if (!popoverHover.value) {
      close();
    }
  }, 100);
};
</script>
