<template>
  <div class="bg-black text-white self-end w-full relative z-30">
    <div class="px-4 py-8 flex flex-wrap lg:items-center lg:py-4 w-full text-sm">
      <div class="w-1/2 lg:w-1/4 lg:flex lg:flex-wrap lg:gap-2">
        <span class="block">
          {{ __('frontend.footer.product_of') }}:
        </span>
        <a
          href="https://lichtoranje.nl/"
          target="_blank"
          class="block"
        >
          <img
            src="@global/assets/media/lichtoranje.png"
            class="mt-1 lg:-mt-1"
          >
        </a>
      </div>

      <div class="w-1/2 pl-4 lg:w-1/4 lg:flex lg:flex-wrap lg:gap-2 lg:items-center">
        <label
          for="location"
          class="block lg:inline-block"
        >
          {{ __('frontend.footer.select_language') }}:
        </label>
        <select
          id="location"
          name="location"
          class="mt-0.5 block w-4/5 text-sm rounded border-0 py-0.5 pl-3 pr-10 text-black focus:ring-2 focus:ring-blue lg:w-auto"
          @change="(event) => redirectToLanguage(event.target.value)"
        >
          <option
            v-for="(name, code) of $page.props.languages"
            :key="code"
            :value="code"
            :selected="code === $page.props.currentLanguage"
          >
            {{ name }}
          </option>
        </select>
      </div>

      <div
        v-if="showLinks"
        class="w-full flex text-xs lg:text-sm gap-4 justify-center mt-8 uppercase lg:w-1/2 lg:mt-0 lg:justify-end lg:gap-6"
      >
        <Link
          :href="route('frontend.index')"
          class="hover:text-orange cursor-pointer"
        >
          {{ __('frontend.footer.links.home') }}
        </Link>
        <a
          href="https://info.eta-monitor.nl"
          target="_blank"
          class="hover:text-orange cursor-pointer"
        >
          {{ __('frontend.footer.links.info') }}
        </a>
        <span
          class="hover:text-orange cursor-pointer"
          @click="showContentModal('contact')"
        >
          {{ __('frontend.footer.links.contact') }}
        </span>
        <span
          class="hover:text-orange cursor-pointer"
          @click="showContentModal('disclaimer')"
        >
          {{ __('frontend.footer.links.disclaimer') }}
        </span>
        <Link
          v-if="$page.props.auth?.recipient"
          class="hover:text-orange cursor-pointer"
          :href="route('frontend.account')"
        >
          {{ __('frontend.footer.links.account') }}
        </Link>
        <Link
          v-if="$page.props.auth?.recipient"
          class="hover:text-orange cursor-pointer"
          :href="route('frontend.logout')"
        >
          {{ __('frontend.footer.links.logout') }}
        </Link>
        <Link
          v-else
          class="hover:text-orange cursor-pointer"
          :href="route('frontend.login')"
        >
          {{ __('frontend.footer.links.login') }}
        </Link>
      </div>
    </div>
  </div>

  <ContentModal
    v-if="showContent"
    :translation-key="contentTranslationKey"
    @close="showContent = false"
  />
</template>

<script setup>
import {defineProps, ref} from 'vue';
import {usePage} from '@inertiajs/vue3';
import ContentModal from './Footer/ContentModal.vue';

defineProps({
  showLinks: {
    type: Boolean,
    required: false,
    default: true,
  },
});

const $page = usePage();
const showContent = ref(false);
const contentTranslationKey = ref(null);

const showContentModal = (key) => {
  showContent.value = true;
  contentTranslationKey.value = key;
};

const redirectToLanguage = (language) => {
  window.location = route(route().current(), {
    ...route().params,
    language: language,
  });
};
</script>
