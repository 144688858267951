<template>
  <div
    class="relative z-50"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
  >
    <div class="fixed inset-0 bg-black bg-opacity-75 transition-opacity" />

    <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
      <div
        class="flex min-h-full justify-center p-4 text-center items-center"
        @click="close"
      >
        <div
          class="relative transform overflow-hidden rounded-lg bg-white p-6 text-left max-w-[500px]"
          @click.stop
        >
          <div
            class="absolute top-4 right-4 hover:cursor-pointer"
            @click="close"
          >
            <i class="fa fa-times" />
          </div>

          <div class="text-center">
            <h3 class="text-2xl font-bold">
              {{ __(`frontend.footer.modals.${translationKey}.title`) }}
            </h3>
            <div class="mt-2 text-sm">
              <p>
                {{ __(`frontend.footer.modals.${translationKey}.content`) }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {defineProps, defineEmits} from 'vue';

defineProps({
  translationKey: {
    type: String,
    required: true,
  },
});
const emit = defineEmits(['close']);

const close = () => emit('close');
</script>
