<template>
  <div class="mt-6">
    <div v-if="!isEditingEmail && !isEditingPhone">
      <h3 class="text-xl font-bold text-center mb-4 lg:text-2xl">
        {{ __('frontend.stop.notifications') }}
      </h3>

      <div class="lg:flex lg:gap-x-4 lg:justify-center">
        <div
          v-if="(!hasEmail && emailEnabled) || (!emailEnabled && shouldShowDisabled)"
          class="w-full lg:w-1/2 mt-2 lg:mt-0 block rounded-md text-white py-3 text-center max-h-12"
          :class="{
            '!bg-custom-layout-color hover:cursor-pointer': emailEnabled && $page.props.customLayout?.customColor,
            'bg-orange hover:bg-orange/90 hover:cursor-pointer': emailEnabled && !$page.props.customLayout?.customColor,
            'hover:cursor-not-allowed bg-gray-300': !emailEnabled,
          }"
          @click="toggleEditingEmail"
        >
          <img
            src="@global/assets/media/stop/email.svg"
            class="mr-2 inline w-6 -mt-0.5"
          >
          {{ __('frontend.stop.email_notification') }}
        </div>
        <div
          v-else-if="hasEmail && emailEnabled"
          class="w-full lg:w-1/2 mt-2 lg:mt-0 block rounded-md py-3 border-2 p-2 text-center"
        >
          <div class="w-full text-2xl text-orange">
            <i class="fal fa-envelope" />
          </div>

          <div class="p-2">
            <I18nTranslate
              keypath="frontend.stop.email_notification_set"
              scope="global"
            >
              <template #minutes>
                <strong>{{ __('frontend.stop.email_notification_set_count', {'minutes': selectedMinutesPrior}) }}</strong>
              </template>
            </I18nTranslate>
          </div>

          <div class="grid grid-cols-2 gap-x-8">
            <div class="text-right">
              <p
                class="text-orange hover:underline hover:cursor-pointer"
                :class="{
                  '!text-custom-layout-color': $page.props.customLayout?.customColor,
                }"
                @click="toggleEditingEmail"
              >
                <i class="fal fa-pencil text-sm" />
                {{ __('frontend.stop.edit') }}
              </p>
            </div>

            <div class="text-left">
              <p
                class="text-orange hover:underline"
                :class="{
                  '!text-custom-layout-color': $page.props.customLayout?.customColor,
                  'hover:cursor-pointer': !loading,
                  'hover:cursor-not-allowed': loading,
                }"
                @click="deleteEmail()"
              >
                {{ __('frontend.stop.delete') }}
              </p>
            </div>
          </div>
        </div>

        <div
          v-if="(!hasPhone && phoneEnabled) || (!phoneEnabled && shouldShowDisabled)"
          class="w-full lg:w-1/2 mt-2 lg:mt-0 block rounded-md text-white py-3 text-center max-h-12"
          :class="{
            '!bg-custom-layout-color hover:cursor-pointer': phoneEnabled && $page.props.customLayout?.customColor,
            'bg-orange hover:bg-orange/90 hover:cursor-pointer': phoneEnabled && !$page.props.customLayout?.customColor,
            'hover:cursor-not-allowed bg-gray-300': !phoneEnabled,
          }"
          @click="toggleEditingPhone"
        >
          <img
            src="@global/assets/media/stop/sms.svg"
            class="mr-2 inline w-6 -mt-0.5"
          >
          {{ __('frontend.stop.text_notification') }}
        </div>
        <div
          v-else-if="hasPhone && phoneEnabled"
          class="w-full lg:w-1/2 mt-2 lg:mt-0 block rounded-md py-3 border-2 p-2 text-center"
        >
          <div class="w-full text-2xl text-orange">
            <i class="fal fa-comment" />
          </div>

          <div class="p-2">
            <I18nTranslate
              keypath="frontend.stop.phone_notification_set"
              scope="global"
            >
              <template #minutes>
                <strong>{{ __('frontend.stop.phone_notification_set_count', {'minutes': selectedMinutesPrior}) }}</strong>
              </template>
            </I18nTranslate>
          </div>

          <div class="grid grid-cols-2 gap-x-8">
            <div class="text-right">
              <p
                class="text-orange hover:underline hover:cursor-pointer"
                :class="{
                  '!text-custom-layout-color': $page.props.customLayout?.customColor,
                }"
                @click="toggleEditingPhone"
              >
                <i class="fal fa-pencil text-sm" />
                {{ __('frontend.stop.edit') }}
              </p>
            </div>

            <div class="text-left">
              <p
                class="text-orange hover:underline"
                :class="{
                  '!text-custom-layout-color': $page.props.customLayout?.customColor,
                  'hover:cursor-pointer': !loading,
                  'hover:cursor-not-allowed': loading,
                }"
                @click="deletePhone"
              >
                {{ __('frontend.stop.delete') }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="isEditingEmail">
      <h3 class="text-xl font-bold text-center mb-4 lg:text-2xl">
        {{ __('frontend.stop.edit_email_notification') }}
      </h3>

      <div class="px-16">
        <div class="w-full pb-4">
          <label
            for="email"
            class="block text-xs text-gray-300"
          >
            {{ __('frontend.stop.email') }}
          </label>
          <div class="mt-0.5">
            <input
              v-model="email"
              type="email"
              name="email"
              class="block w-full rounded-md border-0 py-2 pl-3 text-black ring-1 ring-inset focus:ring-2 focus:ring-inset"
              :class="$page.props.errors.email ? 'border-red ring-red text-red placeholder-red focus:outline-none focus:ring-red focus:border-red' : 'ring-gray-300 focus:ring-gray-300 focus:border-gray-300'"
            >

            <p
              v-if="$page.props.errors.email"
              class="mt-1 text-sm text-red col-span-2 col-start-2"
              v-text="$page.props.errors.email[0]"
            />
          </div>
        </div>

        <div class="w-full pb-4">
          <label
            for="notification_minutes_prior"
            class="block text-xs text-gray-300"
          >
            {{ __('frontend.stop.notification_minutes_prior') }}
          </label>
          <div class="mt-0.5">
            <select
              v-model="selectedMinutesPrior"
              class="rounded-md w-full"
              name="notification_minutes_prior"
            >
              <option
                v-for="option in notificationMinutesPriorOptions"
                :key="option"
                :value="option"
              >
                {{ option }} {{ __('frontend.stop.minutes_prior') }}
              </option>
            </select>
          </div>
        </div>

        <button
          class="w-full block rounded-md bg-orange text-white py-3 text-center hover:bg-orange/90 mb-2"
          :class="{
            '!bg-custom-layout-color': $page.props.customLayout?.customColor,
            'opacity-50 cursor-not-allowed': loading,
          }"
          @click="saveEmail"
        >
          {{ __('frontend.stop.save') }}
          <i
            class="fas fa-chevron-right ml-2 inline w-2 -mt-0.5"
            aria-hidden="true"
          />
        </button>

        <div class="text-center flex items-center justify-center">
          <p
            class="text-orange hover:underline hover:cursor-pointer w-20"
            :class="{
              '!text-custom-layout-color': $page.props.customLayout?.customColor,
            }"
            @click="toggleEditingEmail"
          >
            {{ __('frontend.stop.back') }}
          </p>
        </div>
      </div>
    </div>
    <div v-else-if="isEditingPhone">
      <h3 class="text-xl font-bold text-center mb-4 lg:text-2xl">
        {{ __('frontend.stop.edit_phone_notification') }}
      </h3>

      <div class="px-8">
        <div class="w-full pb-4">
          <label
            for="phone"
            class="block text-xs text-gray-300"
          >
            {{ __('frontend.stop.phone') }}
          </label>
          <input
            v-model="phone"
            type="text"
            name="phone"
            class="block w-full rounded-md border-0 py-2 pl-3 text-black ring-1 ring-inset focus:ring-2 focus:ring-inset focus:ring-blue"
            :class="$page.props.errors.phone ? 'border-red ring-red text-red placeholder-red focus:outline-none focus:ring-red focus:border-red' : 'ring-gray-300 focus:ring-gray-300 focus:border-gray-300'"
          >

          <p
            v-if="$page.props.errors.phone"
            class="mt-1 text-sm text-red col-span-2 col-start-2"
            v-text="$page.props.errors.phone[0]"
          />
        </div>

        <div class="w-full pb-4">
          <label
            for="notification_minutes_prior"
            class="block text-xs text-gray-300"
          >
            {{ __('frontend.stop.notification_minutes_prior') }}
          </label>
          <div class="mt-0.5">
            <select
              v-model="selectedMinutesPrior"
              class="rounded-md w-full"
              name="notification_minutes_prior"
            >
              <option
                v-for="option in notificationMinutesPriorOptions"
                :key="option"
                :value="option"
              >
                {{ option }} {{ __('frontend.stop.minutes_prior') }}
              </option>
            </select>
          </div>
        </div>

        <button
          type="submit"
          class="w-full block rounded-md bg-orange text-white py-3 text-center hover:bg-orange/90 mb-2"
          :class="{
            '!bg-custom-layout-color': $page.props.customLayout?.customColor,
            'opacity-50 cursor-not-allowed': loading,
          }"
          @click="savePhone"
        >
          {{ __('frontend.stop.save') }}
          <i
            class="fas fa-chevron-right ml-2 inline w-2 -mt-0.5"
            aria-hidden="true"
          />
        </button>

        <div class="text-center flex items-center justify-center">
          <p
            class="text-orange hover:underline hover:cursor-pointer w-20"
            :class="{
              '!text-custom-layout-color': $page.props.customLayout?.customColor,
            }"
            @click="toggleEditingPhone"
          >
            {{ __('frontend.stop.back') }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {defineEmits, defineProps, ref} from 'vue';
import axios from 'axios';
import {usePage} from '@inertiajs/vue3';

const props = defineProps({
  stopId: {
    type: String,
    required: true,
  },

  emailEnabled: {
    type: Boolean,
    required: true,
  },

  phoneEnabled: {
    type: Boolean,
    required: true,
  },

  shouldShowDisabled: {
    type: Boolean,
    required: true,
  },

  hasEmail: {
    type: Boolean,
    required: true,
  },

  hasPhone: {
    type: Boolean,
    required: true,
  },

  notificationMinutesPrior: {
    type: Number,
    required: true,
  },
});

const notificationMinutesPriorOptions = ref([
  5,
  10,
  15,
  30,
  45,
  60,
]);

const $page = usePage();
const isEditingEmail = ref(false);
const isEditingPhone = ref(false);
const email = ref(null);
const phone = ref(null);
const loading = ref(false);
const selectedMinutesPrior = ref(props.notificationMinutesPrior);

const $emit = defineEmits([
  'email-updated',
  'email-deleted',
  'phone-updated',
  'phone-deleted',
]);

const toggleEditingEmail = () => {
  if (!props.emailEnabled) {
    return;
  }

  isEditingEmail.value = !isEditingEmail.value;
};

const toggleEditingPhone = () => {
  if (!props.phoneEnabled) {
    return;
  }

  isEditingPhone.value = !isEditingPhone.value;
};

const saveEmail = () => {
  if (loading.value) {
    return;
  }

  loading.value = true;
  axios.patch(route('frontend.api.stops.update-email', props.stopId), {
    email: email.value,
    notification_minutes_prior: selectedMinutesPrior.value,
  }).then(() => {
    $emit('email-updated');
    isEditingEmail.value = false;
    loading.value = false;
    email.value = null;
    clearErrors();
  }).catch((error) => {
    $page.props.errors = error.response.data.errors;
    loading.value = false;
  });
};

const deleteEmail = () => {
  if (loading.value) {
    return;
  }

  email.value = null;
  loading.value = true;
  axios.patch(route('frontend.api.stops.clear-email', props.stopId)).then(() => {
    $emit('email-deleted');
    loading.value = false;
  }).catch(() => {
    loading.value = false;
  });
};

const savePhone = () => {
  if (loading.value) {
    return;
  }

  loading.value = true;
  axios.patch(route('frontend.api.stops.update-phone', props.stopId), {
    phone: phone.value,
    notification_minutes_prior: selectedMinutesPrior.value,
  }).then(() => {
    $emit('phone-updated');
    isEditingPhone.value = false;
    loading.value = false;
    phone.value = null;
    clearErrors();
  }).catch((error) => {
    $page.props.errors = error.response.data.errors;
    loading.value = false;
  });
};

const deletePhone = () => {
  if (loading.value) {
    return;
  }

  phone.value = null;
  loading.value = true;
  axios.patch(route('frontend.api.stops.clear-phone', props.stopId)).then(() => {
    $emit('phone-deleted');
    loading.value = false;
  }).catch(() => {
    loading.value = false;
  });
};

const clearErrors = () => {
  $page.props.errors = {};
};
</script>
