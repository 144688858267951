<template>
  <div class="mt-4 border-blue-600 border rounded-md">
    <div class="bg-blue-600 text-white text-lg font-medium text-center py-2">
      {{ __(`frontend.delivery_planner.steps.service_questions.title.${preStop.translation_key}`) }}
    </div>

    <div
      v-if="fixedServiceQuestions"
      class="bg-gray-100 p-4"
    >
      <span class="block">{{ __('frontend.delivery_planner.steps.service_questions.fixed_service_questions_title' ) }}</span>

      <div class="space-y-2 mt-4">
        <div
          v-for="serviceQuestion in fixedServiceQuestions"
          :key="serviceQuestion.id"
          class="bg-white rounded-md px-3 py-2 shadow-md flex flex-wrap justify-between text-sm"
        >
          <ServiceQuestionField
            :service-question="serviceQuestion"
            :disabled="true"
            :fixed-value="serviceQuestion.answer"
          />
        </div>
      </div>
    </div>

    <div
      v-if="serviceQuestions"
      class="bg-gray-100 p-4"
    >
      <span class="block">{{ __('frontend.delivery_planner.steps.service_questions.service_questions_title' ) }}</span>

      <div class="space-y-2 mt-4">
        <div
          v-for="serviceQuestion in serviceQuestions"
          :key="serviceQuestion.id"
          class="bg-white rounded-md px-3 py-2 shadow-md flex flex-wrap justify-between text-sm"
        >
          <ServiceQuestionField
            :service-question="serviceQuestion"
            :disabled="form.skip_service_questions"
          />
        </div>

        <div class="bg-white rounded-md px-3 py-2 shadow-md flex flex-wrap justify-between text-sm">
          <label class="cursor-pointer">
            <input
              v-model="form.skip_service_questions"
              type="checkbox"
              class="mr-1 -mt-1 inline-block"
            >
            {{ __('frontend.delivery_planner.steps.service_questions.skip_service_questions' ) }}
          </label>
        </div>
      </div>
    </div>
  </div>

  <Buttons
    :previous="previous"
    :validated="validated"
    :next="next"
  />
</template>

<script setup>
import {computed, defineEmits, defineProps, inject} from 'vue';
import Buttons from '@frontend/js/Components/DeliveryPlanner/Buttons.vue';
import ServiceQuestionField from '@frontend/js/Components/DeliveryPlanner/Steps/ServiceQuestionField.vue';

const preStop = inject('preStop');
const form = inject('form');

const emit = defineEmits(['help', 'previous', 'next']);

const props = defineProps({
  serviceQuestions: {
    type: Object,
    required: false,
    default: null,
  },

  fixedServiceQuestions: {
    type: Object,
    required: false,
    default: null,
  },
});

for (const serviceQuestion of props.serviceQuestions ?? []) {
  if (!Object.prototype.hasOwnProperty.call(form.service_questions, serviceQuestion.id)) {
    form.service_questions[serviceQuestion.id] = {
      serviceQuestion: serviceQuestion,
      value: null,
    };
  }
}

for (const serviceQuestion of props.fixedServiceQuestions ?? []) {
  if (!Object.prototype.hasOwnProperty.call(form.fixed_service_questions, serviceQuestion.id)) {
    form.fixed_service_questions[serviceQuestion.id] = {
      serviceQuestion: serviceQuestion,
      value: serviceQuestion.answer,
    };
  }
}

const validated = computed(() => {
  if (form.skip_service_questions) {
    return true;
  }

  if (props.fixedServiceQuestions && !props.serviceQuestions) {
    return true;
  }

  for (const serviceQuestion of props.serviceQuestions ?? []) {
    if (form.service_questions[serviceQuestion.id].value) {
      return true;
    }
  }

  return false;
});

const previous = () => {
  emit('previous');
};

const next = () => {
  emit('next');
};
</script>

