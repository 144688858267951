<template>
  <Head :title="__('frontend.index.title')" />

  <Container>
    <div class="bg-gray-100 p-4 rounded-md">
      <HeaderLogo classes="w-full lg:w-4/5 lg:mx-auto" />

      <span class="text-center mt-4 text-lg block">
        <span v-if="!profile">
          <strong>{{ __('frontend.stop.your_search') }}:</strong>&nbsp;
          <span v-if="reference">{{ reference }}</span>
          <span v-else-if="postcode">{{ postcode }} / {{ houseNumber }}</span>
        </span>
      </span>

      <div class="mt-6 space-y-4">
        <Link
          v-for="stop in stops"
          :key="stop.id"
          class="flex bg-white shadow-md p-4 border-l-4 rounded overflow-hidden items-center space-x-2"
          :class="{'border-amber-300': !stop.vendor_custom_color}"
          :style="stopStyling(stop)"
          :href="stopUrl(stop)"
        >
          <div class="w-1/2">
            <img
              v-if="stop.vendor_logo"
              :src="stop.vendor_logo"
              class="w-40"
            >
          </div>

          <div class="w-1/2 flex space-x-3 items-center">
            <div class="w-1/5">
              <DeliveryTypeImage :type="stop.type" />
            </div>

            <div class="w-3/5">
              <div
                v-if="stop.completed_at"
                :class="{
                  'text-green': !stop.has_deviation_code,
                  'text-red': stop.has_deviation_code,
                }"
              >
                <span class="block text-gray-300 text-xs">
                  <span>{{ __('frontend.stop.completed_at') }}:</span>
                </span>
                <span class="block text-lg">{{ stop.completed_at_day }}</span>
                <span class="block font-bold">{{ stop.completed_at }}</span>
              </div>

              <div v-else>
                <span class="block text-gray-300 text-xs">
                  <span v-if="stop.show_window">{{ __('frontend.stop.expected_between') }}:</span>
                  <span v-else>{{ __('frontend.stop.expected_at') }}:</span>
                </span>
                <span class="block text-lg">{{ stop.eta_day }}</span>
                <span class="block font-bold">{{ stop.eta }}</span>
              </div>
            </div>

            <div class="w-1/10 flex justify-end">
              <i
                v-if="stop.completed_at"
                class="fas fa-check w-2"
                aria-hidden="true"
                :class="{
                  'text-green': !stop.has_deviation_code,
                  'text-red': stop.has_deviation_code,
                }"
              />
              <i
                v-else
                class="fas fa-chevron-right w-2"
                aria-hidden="true"
              />
            </div>
          </div>
        </Link>
      </div>
    </div>
  </Container>
</template>

<script setup>
import {Head} from '@inertiajs/vue3';
import {defineProps} from 'vue';
import Container from '@frontend/js/Components/Container.vue';
import DeliveryTypeImage from '@frontend/js/Components/DeliveryTypeImage.vue';
import HeaderLogo from '@frontend/js/Components/HeaderLogo.vue';

const props = defineProps({
  postcode: {
    type: [String, null],
    required: false,
    default: null,
  },

  houseNumber: {
    type: [String, null],
    required: false,
    default: null,
  },

  reference: {
    type: [String, null],
    required: false,
    default: null,
  },

  stops: {
    type: Array,
    required: true,
  },

  profile: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const stopUrl = function(stop) {
  const url = new URL(stop.frontend_show_url);

  url.searchParams.append('postcode', props.postcode ?? '');
  url.searchParams.append('house_no', props.houseNumber ?? '');
  url.searchParams.append('reference', props.reference ?? '');

  return url.toString();
};

const stopStyling = function(stop) {
  const styling = {};

  if (stop.vendor_custom_color) {
    styling['border-color'] = `#${stop.vendor_custom_color}`;
  }

  return styling;
};
</script>
