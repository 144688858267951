<template>
  <div class="flex flex-wrap items-center">
    <div class="w-full md:w-1/3">
      <img
        src="@frontend/assets/delivery-planner.svg"
        class="mx-auto max-w-full max-h-20"
      >
    </div>

    <Component
      :is="preStop.vendor_website ? 'a' : 'div'"
      :href="preStop.vendor_website"
      target="_blank"
      class="hidden md:block md:w-1/3"
    >
      <img
        v-if="preStop.vendor_logo"
        :src="preStop.vendor_logo"
        class="mx-auto max-w-full max-h-20"
      >
    </Component>

    <Component
      :is="preStop.carrier_website ? 'a' : 'div'"
      :href="preStop.carrier_website"
      target="_blank"
      class="hidden md:block md:w-1/3"
    >
      <img
        v-if="preStop.carrier_logo"
        :src="preStop.carrier_logo"
        class="mx-auto max-w-full max-h-20"
      >
    </Component>
  </div>
</template>

<script setup>
import {inject} from 'vue';

const preStop = inject('preStop');
</script>
