<template>
  <Head :title="__('frontend.index.title')" />

  <Container :show-back-button="true">
    <div class="grid gap-6">
      <div class="bg-gray-100 p-8 rounded-md">
        <div class="text-center">
          <h3 class="text-2xl font-bold">
            {{ __('frontend.account.profile') }}
          </h3>
        </div>

        <div
          v-if="showNameUpdated"
          class="bg-green-500 bg-opacity-25 border-2 border-green-400 rounded-md p-2 text-green-700 w-full inline-flex mb-2"
        >
          <p class="w-full">
            {{ __('frontend.account.name_updated') }}
          </p>

          <div class="text-right">
            <i
              class="fal fa-times cursor-pointer"
              @click="showNameUpdated = false"
            />
          </div>
        </div>

        <div class="mt-6 space-y-3">
          <label
            for="name"
            class="block text-xs text-gray-300"
          >
            {{ __('frontend.account.name') }}
          </label>
          <div class="!mt-0.5">
            <input
              v-model="name"
              type="text"
              name="name"
              class="block w-full rounded-md border-0 py-2 pl-3 text-black ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-blue"
              :class="$page.props.errors.name ? 'border-red ring-red text-red placeholder-red focus:outline-none focus:ring-red focus:border-red' : 'focus:ring-gray-300 focus:border-gray-300'"
            >

            <p
              v-if="$page.props.errors.name"
              class="mt-1 text-sm text-red col-span-2 col-start-2"
              v-text="$page.props.errors.name[0]"
            />
          </div>

          <div>
            <label
              for="email"
              class="block text-xs text-gray-300"
            >
              {{ __('frontend.account.email') }}
            </label>
            <div
              class="mt-0.5"
              v-text="$page.props.auth.recipient.email"
            />
          </div>

          <button
            class="w-1/3 block rounded-md bg-orange text-white py-2 mt-2 text-center hover:bg-orange/90 col-start-2"
            :class="loading ? 'opacity-50 cursor-not-allowed' : ''"
            :disabled="loading"
            @click="updateName"
          >
            {{ __('frontend.account.save') }}
            <i
              class="fas fa-chevron-right ml-2 inline w-2 -mt-0.5"
              aria-hidden="true"
            />
          </button>
        </div>
      </div>

      <div class="bg-gray-100 p-8 rounded-md">
        <div class="text-center">
          <h3 class="text-2xl font-bold">
            {{ __('frontend.account.addresses') }}
          </h3>
        </div>

        <div
          v-if="showAddressDeleted"
          class="bg-green-500 bg-opacity-25 border-2 border-green-400 rounded-md p-2 text-green-700 w-full inline-flex"
        >
          <p class="w-full">
            {{ __('frontend.account.address_deleted') }}
          </p>

          <div class="text-right">
            <i
              class="fal fa-times cursor-pointer"
              @click="showAddressDeleted = false"
            />
          </div>
        </div>

        <p
          v-if="userAddresses.length === 0"
          class="text-center"
          :class="showAddressDeleted ? 'mt-2' : 'mt-0'"
        >
          {{ __('frontend.account.no_addresses') }}
        </p>

        <div
          v-for="address in userAddresses"
          :key="address.id"
          class="space-y-4 mt-4"
        >
          <div class="flex bg-white shadow-md p-4 rounded overflow-hidden items-center space-x-2">
            <div class="w-11/12">
              <p>
                {{ address.street }} {{ address.house_no }} {{ address.address_addition }}<br>
                {{ address.postcode }}, {{ address.city }}<br>
                {{ address.country_name }}
              </p>
            </div>

            <div class="row-start-2 w-full text-right">
              <button
                class="text-xl text-red-500 rounded-md px-1.5 py-0.5 hover:text-opacity-75"
                @click="deleteAddress(address.id)"
              >
                <i class="fas fa-trash" />
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="bg-gray-100 p-8 rounded-md">
        <div class="text-center">
          <h3 class="text-2xl font-bold">
            {{ __('frontend.account.new_password') }}
          </h3>
        </div>

        <div
          v-if="showPasswordUpdated"
          class="bg-green-500 bg-opacity-25 border-2 border-green-400 rounded-md p-2 text-green-700 w-full inline-flex mb-2"
        >
          <p class="w-full">
            {{ __('frontend.account.password_updated') }}
          </p>

          <div class="text-right">
            <i
              class="fal fa-times cursor-pointer"
              @click="showPasswordUpdated = false"
            />
          </div>
        </div>

        <form
          @submit.prevent="form.patch(route('frontend.account.update-password'), {
            onSuccess: () => {
              showPasswordUpdated = true;
              form.reset();
            },
          })"
        >
          <div class="mt-6 space-y-3">
            <div>
              <label
                for="current_password"
                class="block text-xs text-gray-300"
              >
                {{ __('frontend.account.current_password') }}
              </label>
              <div class="mt-0.5">
                <input
                  v-model="form.current_password"
                  type="password"
                  name="current_password"
                  class="block w-full rounded-md border-0 py-2 pl-3 text-black ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-blue"
                  :class="$page.props.errors.current_password ? 'border-red ring-red text-red placeholder-red focus:outline-none focus:ring-red focus:border-red' : 'focus:ring-gray-300 focus:border-gray-300'"
                >

                <p
                  v-if="$page.props.errors.current_password"
                  class="mt-1 text-sm text-red col-start-2 col-span-2"
                  v-text="$page.props.errors.current_password"
                />
              </div>
            </div>

            <div>
              <label
                for="current_password"
                class="block text-xs text-gray-300"
              >
                {{ __('frontend.account.new_password') }}
              </label>
              <div class="mt-0.5">
                <input
                  v-model="form.new_password"
                  type="password"
                  name="new_password"
                  class="block w-full rounded-md border-0 py-2 pl-3 text-black ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-blue"
                  :class="$page.props.errors.new_password ? 'border-red ring-red text-red placeholder-red focus:outline-none focus:ring-red focus:border-red' : 'focus:ring-gray-300 focus:border-gray-300'"
                >

                <p
                  v-if="$page.props.errors.new_password"
                  class="mt-1 text-sm text-red col-start-2 col-span-2"
                  v-text="$page.props.errors.new_password"
                />
              </div>
            </div>

            <div>
              <label
                for="current_password"
                class="block text-xs text-gray-300"
              >
                {{ __('frontend.account.new_password_confirmation') }}
              </label>
              <div class="mt-0.5">
                <input
                  v-model="form.new_password_confirmation"
                  type="password"
                  name="new_password_confirmation"
                  class="block w-full rounded-md border-0 py-2 pl-3 text-black ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-blue"
                  :class="$page.props.errors.new_password_confirmation ? 'border-red ring-red text-red placeholder-red focus:outline-none focus:ring-red focus:border-red' : 'focus:ring-gray-300 focus:border-gray-300'"
                >

                <p
                  v-if="$page.props.errors.new_password_confirmation"
                  class="mt-1 text-sm text-red col-start-2 col-span-2"
                  v-text="$page.props.errors.new_password_confirmation"
                />
              </div>
            </div>
          </div>

          <button
            type="submit"
            class="w-2/3 block rounded-md bg-orange text-white py-2 mt-2 text-center hover:bg-orange/90 col-start-2"
          >
            {{ __('frontend.account.update_password') }}
            <i
              class="fas fa-chevron-right ml-2 inline w-2 -mt-0.5"
              aria-hidden="true"
            />
          </button>
        </form>
      </div>
    </div>
  </Container>
</template>

<script setup>
import {Head, useForm, usePage} from '@inertiajs/vue3';
import {defineProps, ref} from 'vue';
import axios from 'axios';
import {__} from '@global/js/helpers';
import Container from '../../Components/Container.vue';

const props = defineProps({
  addresses: {
    type: Array,
    required: false,
    default: () => [],
  },
});

const $page = usePage();
const name = ref($page.props.auth.recipient.name);
const loading = ref(false);
const userAddresses = ref(props.addresses);
const showAddressDeleted = ref(false);
const showPasswordUpdated = ref(false);
const showNameUpdated = ref(false);

const form = useForm({
  current_password: null,
  new_password: null,
  new_password_confirmation: null,
});

const updateName = () => {
  loading.value = true;

  axios.patch(route('frontend.account.update-name'), {
    name: name.value,
  }).then(() => {
    loading.value = false;
    showNameUpdated.value = true;
  }).catch((error) => {
    $page.props.errors = error.response.data.errors;
    loading.value = false;
  });
};

const deleteAddress = (id) => {
  if (confirm(__('frontend.account.delete_address_confirm'))) {
    axios.delete(route('frontend.account.delete-address', {
      address: id,
    })).then(() => {
      showAddressDeleted.value = true;
      userAddresses.value.splice(userAddresses.value.findIndex((address) => address.id === id), 1);
    });
  }
};
</script>
