<template>
  <Head :title="__('frontend.delivery_planner.title')" />

  <Container>
    <div class="bg-white p-6 rounded-md lg:p-8">
      <Header />

      <div class="mt-4 border-blue-600 border rounded-md overflow-hidden">
        <div class="bg-blue-600 text-white text-lg font-medium text-center py-2">
          {{ __(`frontend.delivery_planner.success.title.${preStop.translation_key}`) }}
        </div>

        <div class="bg-gray-100 p-4">
          <span class="block text-center">
            {{ __('frontend.delivery_planner.success.message') }}
          </span>
        </div>
      </div>
    </div>
  </Container>
</template>

<script setup>
import {Head} from '@inertiajs/vue3';
import Container from '@frontend/js/Components/DeliveryPlanner/Container.vue';
import {provide, defineProps} from 'vue';
import Header from '@frontend/js/Components/DeliveryPlanner/Header.vue';

const props = defineProps({
  preStop: {
    type: Object,
    required: true,
  },
});

provide('preStop', props.preStop);
</script>
