<template>
  <div class="mt-4 flex gap-4 justify-between">
    <div class="w-1/2 md:w-1/3">
      <slot name="before-previous" />

      <button
        v-if="previous"
        type="button"
        class="w-full block rounded-md bg-white border py-3 text-center hover:bg-gray-200"
        :class="{
          'text-green border-green': !$page.props.customLayout?.customColor,
          '!text-custom-layout-color !border-custom-layout-color': $page.props.customLayout?.customColor,
        }"
        @click="previous"
      >
        <i
          class="fas fa-chevron-left mr-2 inline w-2 -mt-0.5"
          aria-hidden="true"
        />
        {{ __('frontend.delivery_planner.previous') }}
      </button>

      <slot name="after-previous" />
    </div>
    <div class="w-1/2 md:w-1/3">
      <slot name="before-next" />

      <button
        type="button"
        class="w-full block rounded-md text-white py-3 text-center"
        :class="{
          '!bg-custom-layout-color hover:opacity-90': validated && $page.props.customLayout?.customColor,
          '!bg-custom-layout-color cursor-not-allowed opacity-50': !validated && $page.props.customLayout?.customColor,
          'bg-green/50 cursor-not-allowed': !validated && !$page.props.customLayout?.customColor,
          'bg-green hover:bg-green/90': validated && !$page.props.customLayout?.customColor,
        }"
        :disabled="!validated && !form.processing"
        @click="next"
      >
        {{ nextLabel }}
        <i
          class="fas fa-chevron-right ml-2 inline w-2 -mt-0.5"
          aria-hidden="true"
        />
      </button>

      <slot name="after-next" />
    </div>
  </div>
</template>

<script setup>
import {defineProps, inject} from 'vue';
import {__} from '@global/js/helpers';

defineProps({
  previous: {
    type: Function,
    required: false,
    default: null,
  },
  next: {
    type: Function,
    required: false,
    default: null,
  },
  nextLabel: {
    type: String,
    required: false,
    default: () => __('frontend.delivery_planner.next'),
  },
  validated: {
    type: Boolean,
    required: true,
  },
});

const form = inject('form');
</script>
