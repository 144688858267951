<template>
  <div class="mt-4 border-blue-600 border rounded-md overflow-hidden">
    <div class="bg-blue-600 text-white text-lg font-medium text-center py-2">
      {{ __(`frontend.delivery_planner.steps.housing_type.title.${preStop.translation_key}`) }}
    </div>

    <div
      v-if="!form.select_housing_type_floor_option"
      class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4 p-4 bg-gray-100"
    >
      <div
        v-for="housingType in housingTypes"
        :key="housingType.id"
        class="flex items-center content-center flex-wrap bg-white shadow-md p-4 rounded-md cursor-pointer"
        :class="{'border-2 border-blue-600': housingType.id === form.housing_type?.id}"
        @click="selectHousingType(housingType)"
      >
        <img :src="housingType.image">

        <span class="block text-sm mt-4 text-center w-full">
          {{ housingType.name }}
        </span>
      </div>
    </div>

    <div
      v-if="form.select_housing_type_floor_option"
      class="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-3 gap-4 p-4 bg-gray-100"
    >
      <div class="flex items-center content-center flex-wrap p-4 rounded-md">
        <img
          :src="form.housing_type.image"
          class="max-h-32 mx-auto"
        >

        <span class="block text-sm mt-4 text-center w-full">
          {{ form.housing_type.name }}
        </span>
      </div>

      <div v-if="shouldAskEntranceFloor">
        <div class="bg-white shadow-md p-4 rounded-md">
          <span class="text-sm block">
            {{ __('frontend.delivery_planner.steps.housing_type.entrance_floor') }}
          </span>
          <ul class="mt-2 space-y-2">
            <li
              v-for="entranceFloorOption in form.housing_type.entranceFloorOptions"
              :key="entranceFloorOption.id"
            >
              <label class="cursor-pointer">
                <input
                  v-model="form.entrance_floor_option"
                  type="radio"
                  name="entrance_floor_option"
                  :value="entranceFloorOption"
                  class="-mt-1 mr-1"
                  @click="selectEntranceFloor(entranceFloorOption)"
                >
                {{ entranceFloorOption.name }}
              </label>
            </li>
            <li v-if="form.housing_type.housingTypeCarrier.open_floor_input">
              <label class="cursor-pointer">
                <input
                  v-model="form.select_other_entrance_floor"
                  type="radio"
                  name="entrance_floor_option"
                  :value="true"
                  class="-mt-1 mr-1"
                  @click="selectEntranceFloor('other')"
                >
                {{ __('frontend.delivery_planner.steps.housing_type.other_floor') }}
              </label>
            </li>
            <li v-if="form.select_other_entrance_floor">
              <label
                for="custom_entrance_floor"
                class="block text-xs text-gray-300"
              >
                {{ __('frontend.delivery_planner.steps.housing_type.floor') }}
              </label>
              <div class="mt-0.5">
                <input
                  id="custom_entrance_floor"
                  v-model="form.custom_entrance_floor"
                  type="number"
                  name="custom_entrance_floor"
                  class="block w-full rounded-md border-0 py-2 pl-3 text-black ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-blue"
                >
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div v-if="shouldAskDeliveryFloor">
        <div class="bg-white shadow-md p-4 rounded-md">
          <span class="text-sm block">
            <span v-if="preStop.is_pickup">{{ __('frontend.delivery_planner.steps.housing_type.pickup_floor') }}</span>
            <span v-else-if="preStop.is_service">{{ __('frontend.delivery_planner.steps.housing_type.service_floor') }}</span>
            <span v-else>{{ __('frontend.delivery_planner.steps.housing_type.delivery_floor') }}</span>
          </span>
          <ul class="mt-2 space-y-2">
            <li
              v-for="deliveryFloorOption in form.housing_type.deliveryFloorOptions"
              :key="deliveryFloorOption.id"
            >
              <label class="cursor-pointer">
                <input
                  v-model="form.delivery_floor_option"
                  type="radio"
                  name="delivery_floor_option"
                  class="-mt-1 mr-1"
                  :value="deliveryFloorOption"
                  @click="selectDeliveryFloor(deliveryFloorOption)"
                >
                {{ deliveryFloorOption.name }}
              </label>
            </li>
            <li v-if="form.housing_type.housingTypeCarrier.open_floor_input">
              <label class="cursor-pointer">
                <input
                  v-model="form.select_other_delivery_floor"
                  type="radio"
                  class="-mt-1 mr-1"
                  :value="true"
                  @click="selectDeliveryFloor('other')"
                >
                {{ __('frontend.delivery_planner.steps.housing_type.other_floor') }}
              </label>
            </li>
            <li v-if="form.select_other_delivery_floor">
              <label
                for="custom_delivery_floor"
                class="block text-xs text-gray-300"
              >
                {{ __('frontend.delivery_planner.steps.housing_type.floor') }}
              </label>
              <div class="mt-0.5">
                <input
                  id="custom_delivery_floor"
                  v-model="form.custom_delivery_floor"
                  type="number"
                  name="custom_delivery_floor"
                  class="block w-full rounded-md border-0 py-2 pl-3 text-black ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-blue"
                >
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div
        v-if="shouldAskElevatorQuestion"
        class="col-start-1 md:col-start-2 col-span-1 md:col-span-2"
      >
        <div class="text-sm">
          <label
            class="cursor-pointer"
            :class="{'text-red': elevatorValidation}"
          >
            <input
              v-model="form.elevator"
              type="checkbox"
              class="mr-1 -mt-1 inline-block"
            >
            {{ __('frontend.delivery_planner.steps.housing_type.elevator') }}
          </label>
        </div>
      </div>

      <div
        v-if="extraFloorCosts && !extraFloorCosts.isZero()"
        class="col-start-1 md:col-start-2 col-span-1 md:col-span-2"
      >
        <div class="text-2xl">
          {{ __('frontend.delivery_planner.steps.housing_type.extra_floor_costs', {money: formatMoney(extraFloorCosts)}) }}
        </div>
      </div>
    </div>
  </div>

  <Buttons
    :previous="previous"
    :validated="validated"
    :next="next"
  />
</template>

<script setup>
import {computed, defineEmits, defineProps, inject, ref} from 'vue';
import Buttons from '@frontend/js/Components/DeliveryPlanner/Buttons.vue';
import Decimal from '@agrora/decimal';
import {formatMoney} from '@global/js/helpers';

const preStop = inject('preStop');
const form = inject('form');
const costs = inject('costs');

const emit = defineEmits(['help', 'previous', 'next']);

const props = defineProps({
  housingTypes: {
    type: Object,
    required: true,
  },
  skipEntranceFloor: {
    type: Boolean,
    required: true,
  },
  skipDeliveryFloor: {
    type: Boolean,
    required: true,
  },
});

const elevatorValidation = ref(false);

const shouldAskElevatorQuestion = computed(() => {
  return (form.entrance_floor_option && form.entrance_floor_option.floor > 0)
    || (form.custom_entrance_floor && form.custom_entrance_floor > 0)
    || (form.delivery_floor_option && form.delivery_floor_option.floor > 0)
    || (form.custom_delivery_floor && form.custom_delivery_floor > 0);
});

const shouldAskFloorOptions = computed(() => {
  return shouldAskEntranceFloor.value || shouldAskDeliveryFloor.value;
});

const shouldAskEntranceFloor = computed(() => {
  if (props.skipEntranceFloor) {
    return false;
  }

  if (form.housing_type?.entranceFloorOptions?.length > 0) {
    return true;
  }

  if (form.select_other_entrance_floor) {
    return true;
  }

  return false;
});

const shouldAskDeliveryFloor = computed(() => {
  if (props.skipDeliveryFloor) {
    return false;
  }

  if (form.housing_type?.deliveryFloorOptions?.length > 0) {
    return true;
  }

  if (form.select_other_delivery_floor) {
    return true;
  }

  return false;
});

const validated = computed(() => {
  if (!form.select_housing_type_floor_option && form.housing_type) {
    return true;
  }

  if (
    shouldAskEntranceFloor.value
    && (
      (!form.select_other_entrance_floor && !form.entrance_floor_option)
      || (form.select_other_entrance_floor && !form.custom_entrance_floor)
    )
  ) {
    return false;
  }

  if (
    shouldAskDeliveryFloor.value
    && (
      (!form.select_other_delivery_floor && !form.delivery_floor_option)
      || (form.select_other_delivery_floor && !form.custom_delivery_floor)
    )
  ) {
    return false;
  }

  return !!form.housing_type;
});

const extraFloorCosts = computed(() => {
  let extraCosts = Decimal.fromString('0.00');

  if (costs.housing_type_floor_options_costs) {
    extraCosts = extraCosts.add(costs.housing_type_floor_options_costs);
  }

  if (costs.floor_addition_weight_costs) {
    extraCosts = extraCosts.add(costs.floor_addition_weight_costs);
  }

  return extraCosts;
});

const previous = () => {
  if (form.select_housing_type_floor_option) {
    deselectHousingType();
    return;
  }
  emit('previous');
};

const next = () => {
  elevatorValidation.value = false;
  if (shouldAskFloorOptions.value && !form.select_housing_type_floor_option) {
    form.select_housing_type_floor_option = true;
    return;
  }

  if (
    !form.elevator
    && (
      (form.housing_type.housingTypeCarrier.elevator_required_floor && form.custom_entrance_floor && form.custom_entrance_floor >= form.housing_type.housingTypeCarrier.elevator_required_floor) // required for this floor
      || (form.housing_type.housingTypeCarrier.elevator_required_floor && form.custom_delivery_floor && form.custom_delivery_floor >= form.housing_type.housingTypeCarrier.elevator_required_floor) // required for this floor
      || (form.entrance_floor_option && form.entrance_floor_option.floorOptionCarrier && form.entrance_floor_option.floorOptionCarrier.elevator_required) // required for this floor
      || (form.delivery_floor_option && form.delivery_floor_option.floorOptionCarrier && form.delivery_floor_option.floorOptionCarrier.elevator_required) // required for this floor
    )
  ) {
    elevatorValidation.value = true;
    emit('help');
    return;
  }

  emit('next');
};

const selectHousingType = (housingType) => {
  form.housing_type = housingType;
};

const deselectHousingType = () => {
  form.select_housing_type_floor_option = false;
};

const selectEntranceFloor = (floorOption) => {
  if (floorOption === 'other') {
    form.entrance_floor_option = null;
    form.select_other_entrance_floor = true;
    return;
  }

  form.entrance_floor_option = floorOption;
  form.select_other_entrance_floor = false;
  form.custom_entrance_floor = null;
};
const selectDeliveryFloor = (floorOption) => {
  if (floorOption === 'other') {
    form.delivery_floor_option = null;
    form.select_other_delivery_floor = true;
    return;
  }

  form.delivery_floor_option = floorOption;
  form.select_other_delivery_floor = false;
  form.custom_delivery_floor = null;
};
</script>

