<template>
  <div
    class="relative z-50"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
  >
    <div class="fixed inset-0 bg-black bg-opacity-75 transition-opacity" />

    <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
      <div
        class="flex min-h-full justify-center p-4 text-center items-center"
        @click="close"
      >
        <div
          class="relative transform overflow-hidden rounded-lg bg-white p-6 text-left max-w-[500px]"
          @click.stop
        >
          <div
            class="absolute top-4 right-4 hover:cursor-pointer"
            @click="close"
          >
            <i class="fa fa-times" />
          </div>

          <h3 class="text-2xl text-center font-bold">
            {{ title }}
          </h3>
          <div class="mt-2 text-sm">
            <table class="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    {{ __('frontend.delivery_planner.shipment_lines.code') }}
                  </th>
                  <th class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    {{ __('frontend.delivery_planner.shipment_lines.description') }}
                  </th>
                  <th class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    {{ __('frontend.delivery_planner.shipment_lines.quantity') }}
                  </th>
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-200">
                <tr
                  v-for="line of lines"
                  :key="line.code"
                >
                  <td class="px-3 py-4 text-sm text-gray-500">
                    {{ line.code }}
                  </td>
                  <td class="px-3 py-4 text-sm text-gray-500">
                    {{ line.description }}
                  </td>
                  <td class="px-3 py-4 text-sm text-gray-500">
                    {{ line.quantity }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {defineEmits, defineProps} from 'vue';

const emit = defineEmits(['close']);

defineProps({
  title: {
    type: String,
    required: true,
  },
  lines: {
    type: Array,
    required: true,
  },
});

const close = () => emit('close');
</script>
