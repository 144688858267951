<template>
  <div class="mt-4 border-blue-600 border rounded-md overflow-hidden bg-gray-100">
    <div class="bg-blue-600 text-white text-lg font-medium text-center py-2">
      {{ __(`frontend.delivery_planner.steps.additional_questions.title.${preStop.translation_key}`) }}
    </div>

    <div class="p-4">
      <div
        v-if="additionalQuestionText"
        class="block mb-4"
      >
        <!-- eslint-disable vue/no-v-html -->
        <p v-html="additionalQuestionText" />
        <!-- eslint-enable -->
      </div>

      <div class="grid space-y-2">
        <div
          v-for="question in form.additional_questions"
          :key="question.id"
          class="p-2 bg-white shadow-md rounded-md"
        >
          <p>
            {{ question.text }}
          </p>

          <div class="pt-2">
            <input
              v-model="form.additional_questions.find((element) => element.id === question.id).answer"
              :name="'question' + question.id"
              type="radio"
              class="-mt-1 mr-1"
              :value="true"
            >

            {{ __('frontend.common.yes') }}

            <input
              v-model="form.additional_questions.find((element) => element.id === question.id).answer"
              :name="'question' + question.id"
              type="radio"
              class="-mt-1 mr-1 ml-2"
              :value="false"
            >

            {{ __('frontend.common.no') }}
          </div>

          <p
            v-if="question.answer === true"
            class="text-sm pt-2"
          >
            {{ question.yes_text }}
          </p>

          <p
            v-if="question.answer === false"
            class="text-sm pt-2"
          >
            {{ question.no_text }}
          </p>
        </div>
      </div>
    </div>
  </div>

  <Buttons
    :previous="previous"
    :validated="validated"
    :next="next"
  />
</template>

<script setup>
import {computed, defineEmits, defineProps, inject} from 'vue';
import Buttons from '@frontend/js/Components/DeliveryPlanner/Buttons.vue';

const preStop = inject('preStop');
const form = inject('form');
const costs = inject('costs');

const props = defineProps({
  additionalQuestions: {
    type: Array,
    required: true,
  },

  additionalQuestionText: {
    type: String,
    required: false,
    default: null,
  },
});

form.additional_questions = [];

props.additionalQuestions.forEach(question => {
  if (question.conditions.length === 0) {
    form.additional_questions.push(question);

    return;
  }

  let shouldPush = true;

  question.conditions.every(condition => {
    if (condition.type === 'type_question_answer_yes') {
      if (form.additional_questions.find(q => q.code === condition.value)?.answer !== true) {
        shouldPush = false;
        return false;
      }
    }

    if (condition.type === 'type_question_answer_no') {
      if (form.additional_questions.find(q => q.code === condition.value)?.answer !== false) {
        shouldPush = false;
        return false;
      }
    }

    if (condition.type === 'type_delivery_floor') {
      if (!!form.delivery_floor_option?.floor !== (condition.value === '1')) {
        shouldPush = false;
        return false;
      }
    }

    if (condition.type === 'type_cod_amount') {
      if (costs.cash_on_delivery_amount_left_to_pay.isZero() === (condition.value === '1')) {
        shouldPush = false;
        return false;
      }
    }

    return true;
  });

  if (shouldPush) {
    form.additional_questions.push(question);

    return;
  }

  question.answer = null;
});

const emit = defineEmits(['help', 'previous', 'next']);

const validated = computed(() => {
  const idsToBeValidated = [];

  form.additional_questions.forEach(question => {
    idsToBeValidated.push(question.id);
  });

  let valid = true;

  props.additionalQuestions.forEach(question => {
    if (question.id === idsToBeValidated.find(id => id === question.id)) {
      if (question.answer === null || question.answer === undefined) {
        valid = false;
      }
    }
  });

  return valid;
});

const previous = () => {
  emit('previous');
};

const next = () => {
  emit('next');
};
</script>
