<template>
  <Head :title="__('frontend.delivery_planner.title')" />

  <Container>
    <form
      class="bg-white p-6 rounded-md lg:p-8"
      @submit.prevent="next"
    >
      <Header />

      <div
        v-if="Object.keys(page.props.errors).length > 0"
        class="mt-4 bg-red-50 border-l-8 border-red-700 mb-2"
      >
        <div class="flex items-center">
          <div class="p-2">
            <div class="flex items-center">
              <p class="px-4 slim:px-3 py-2 slim:py-1 text-red-700">
                {{ __('frontend.delivery_planner.error_list.title') }}
              </p>
            </div>

            <div class="px-12 mb-4">
              <ul>
                <li
                  v-for="(error, i) in page.props.errors"
                  :key="`error-${i}`"
                  class="text-md font-bold text-red-600 text-sm"
                >
                  {{ error }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div class="flex flex-wrap xl:flex-nowrap xl:space-x-4">
        <div class="w-full xl:w-2/3">
          <Steps
            :total-steps="steps.length"
            :current-step-index="currentStepIndex"
            :current-step="currentStep"
          />

          <Component
            :is="currentStepComponent"
            v-if="currentStep"
            :key="currentStepIndex"
            v-bind="currentStep.options"
            @help="showHelpModal = true"
            @previous="previous"
            @next="next"
          />
        </div>

        <div class="w-full xl:w-1/3">
          <Sidebar
            @help="showHelpModal = true"
          />
        </div>
      </div>
    </form>
  </Container>

  <HelpModal
    v-if="showHelpModal"
    @close="showHelpModal = false"
  />
</template>

<script setup>
import {defineProps, onMounted, provide, ref, shallowRef, watch} from 'vue';
import {Head, useForm, usePage} from '@inertiajs/vue3';
import HelpModal from '@frontend/js/Components/DeliveryPlanner/Modals/HelpModal.vue';
import Decimal from '@agrora/decimal';
import Header from '@frontend/js/Components/DeliveryPlanner/Header.vue';
import Container from '../../Components/DeliveryPlanner/Container.vue';
import Steps from '../../Components/DeliveryPlanner/Steps.vue';
import Sidebar from '../../Components/DeliveryPlanner/Sidebar.vue';
import StepAdditionalQuestions from '../../Components/DeliveryPlanner/Steps/AdditionalQuestions.vue';
import StepConfirm from '../../Components/DeliveryPlanner/Steps/Confirm.vue';
import StepDate from '../../Components/DeliveryPlanner/Steps/Date.vue';
import StepHousingType from '../../Components/DeliveryPlanner/Steps/HousingType.vue';
import StepServiceQuestions from '../../Components/DeliveryPlanner/Steps/ServiceQuestions.vue';

const page = usePage();
const props = defineProps({
  preStop: {
    type: Object,
    required: true,
  },
  steps: {
    type: Object,
    required: true,
  },
  settings: {
    type: Object,
    required: true,
  },
});

const form = useForm({
  city: props.preStop.city,
  postcode: props.preStop.postcode,
  street: props.preStop.street,
  house_no: props.preStop.house_no,
  address_addition: props.preStop.address_addition,
  remarks: props.preStop.remarks,
  notification_phone_enabled: props.preStop.notification_phone_enabled,
  notification_phone: props.preStop.notification_phone,
  notification_email_enabled: props.preStop.notification_email_enabled,
  notification_email: props.preStop.notification_email,
  week_offset: 0,
  time_window: null,
  time_window_id: null,
  eta_from: props.preStop.eta_from,
  eta_to: props.preStop.eta_to,
  housing_type: null,
  select_housing_type_floor_option: null,
  entrance_floor_option: null,
  select_other_entrance_floor: false,
  custom_entrance_floor: null,
  delivery_floor_option: null,
  select_other_delivery_floor: false,
  custom_delivery_floor: null,
  elevator: false,
  service_questions: {},
  fixed_service_questions: {},
  skip_service_questions: false,
  pay_cash_on_delivery: props.settings.cashOnDeliveryRequired,
  pay_additional: props.settings.additionalPaymentsRequired,
  create_payment_link: false,
  do_pay: false,
  confirmed: false,
  additional_questions: null,
});

const costs = useForm({
  date_costs: Decimal.fromString('0.00'),
  housing_type_floor_options_costs: Decimal.fromString('0.00'),
  floor_addition_weight_costs: Decimal.fromString('0.00'),
  service_question_costs: Decimal.fromString('0.00'),
  cash_on_delivery_amount_left_to_pay: Decimal.fromString(props.preStop.cash_on_delivery_amount_left_to_pay),
  service_amount_left_to_pay: Decimal.fromString(props.preStop.service_amount_left_to_pay),
  already_paid: Decimal.fromString(props.preStop.already_paid),
  // generated
  total_delivery_planner_costs: Decimal.from('0.00'),
  total_price: Decimal.from('0.00'),
  total_paying: Decimal.from('0.00'),
  extra_cash_on_delivery_amount_left_to_pay: Decimal.fromString('0.00'),
});

const currentStepIndex = ref(0);
const currentStep = ref(null);
const currentStepComponent = shallowRef(null);
const showHelpModal = shallowRef(false);
const stepComponents = shallowRef({
  date: StepDate,
  housing_type: StepHousingType,
  service_questions: StepServiceQuestions,
  additional_questions: StepAdditionalQuestions,
  confirm: StepConfirm,
});

const calculateCosts = () => {
  calculateDateCosts();
  calculateHousingTypeCosts();
  calculateServiceQuestionsCosts();
  calculateGeneratedCostsFields();
};
const calculateDateCosts = () => {
  costs.date_costs = Decimal.fromString(form?.time_window?.price ?? '0.00');
};
const calculateHousingTypeCosts = () => {
  costs.housing_type_floor_options_costs = Decimal.fromString('0.00');
  costs.floor_addition_weight_costs = Decimal.fromString('0.00');

  if (!props.settings.applyFloorAddition || !form.housing_type) {
    return;
  }

  if (form.entrance_floor_option && form.entrance_floor_option.floorOptionCarrier) {
    costs.housing_type_floor_options_costs = costs.housing_type_floor_options_costs.add(
      Decimal.fromString(form.entrance_floor_option.floorOptionCarrier.price),
    );
  }

  if (form.delivery_floor_option && form.delivery_floor_option.floorOptionCarrier) {
    costs.housing_type_floor_options_costs = costs.housing_type_floor_options_costs.add(
      Decimal.fromString(form.delivery_floor_option.floorOptionCarrier.price),
    );
  }

  if (form.housing_type.housingTypeCarrier.floor_addition) {
    const weight = props.preStop.total_weight === 0 ? 1 : props.preStop.total_weight;

    const floor = form.delivery_floor_option
      ? form.delivery_floor_option.floor
      : form.custom_delivery_floor;

    if (floor > 0) {
      let floorAdditionCosts = Decimal.fromString(form.housing_type.housingTypeCarrier.floor_addition_price)
          .multiply(Math.floor(weight / form.housing_type.housingTypeCarrier.floor_addition_weight));

      if (
        form.housing_type.housingTypeCarrier.floor_addition_max_price
        && floorAdditionCosts.isGreaterThan(form.housing_type.housingTypeCarrier.floor_addition_max_price)
      ) {
        floorAdditionCosts = Decimal.fromString(form.housing_type.housingTypeCarrier.floor_addition_max_price);
      }

      costs.floor_addition_weight_costs = costs.floor_addition_weight_costs.add(floorAdditionCosts);
    }
  }
};
const calculateServiceQuestionsCosts = () => {
  costs.service_question_costs = Decimal.fromString('0.00');

  for (const serviceQuestionId of Object.keys(form.service_questions)) {
    const serviceQuestion = form.service_questions[serviceQuestionId];

    if (serviceQuestion.value) {
      const amount = serviceQuestion.serviceQuestion.type === 'quantity'
        ? parseInt(serviceQuestion.value)
        : 1;

      costs.service_question_costs = costs.service_question_costs.add(
        Decimal.fromString(serviceQuestion.serviceQuestion.price)
          .multiply(amount),
      );
    }
  }
};
const calculateGeneratedCostsFields = () => {
  costs.total_price = Decimal.fromString('0.00')
    .add(costs.date_costs)
    .add(costs.housing_type_floor_options_costs)
    .add(costs.floor_addition_weight_costs)
    .add(costs.service_question_costs)
    .add(costs.service_amount_left_to_pay)
    .add(costs.cash_on_delivery_amount_left_to_pay);

  costs.extra_cash_on_delivery_amount_left_to_pay = costs.total_price
    .subtract(costs.cash_on_delivery_amount_left_to_pay);

  costs.total_paying = Decimal.fromString('0.00');

  if (form.pay_additional) {
    costs.total_paying = costs.total_paying
      .add(costs.date_costs)
      .add(costs.housing_type_floor_options_costs)
      .add(costs.floor_addition_weight_costs)
      .add(costs.service_question_costs)
      .add(costs.service_amount_left_to_pay);
  }
  if (form.pay_cash_on_delivery) {
    costs.total_paying = costs.total_paying.add(costs.cash_on_delivery_amount_left_to_pay);
  }
};

watch(currentStepIndex, (index) => {
  currentStep.value = props.steps[index];

  currentStepComponent.value = stepComponents.value[currentStep.value.key];
}, { immediate: true });

watch(form, () => {
  calculateCosts();
}, { immediate: true });

watch(() => form.housing_type, () => {
  form.entrance_floor_option = form.housing_type.entranceFloorOptions.length === 1
    ? form.housing_type.entranceFloorOptions[0]
    : null;
  form.delivery_floor_option = form.housing_type.deliveryFloorOptions.length === 1
    ? form.housing_type.deliveryFloorOptions[0]
    : null;

  if (props.preStop.floor) {
    const entranceFloor = form.housing_type.entranceFloorOptions
      .filter(value => value.floor === props.preStop.floor);

    if (entranceFloor.length > 0) {
      form.entrance_floor_option = entranceFloor[0];
    } else if (form.housing_type.housingTypeCarrier.open_floor_input) {
      form.entrance_floor_option = null;
      form.select_other_entrance_floor = true;
      form.custom_entrance_floor = props.preStop.floor;
    }
  }
  if (props.preStop.delivery_floor) {
    const deliveryFloor = form.housing_type.deliveryFloorOptions
      .filter(value => value.floor === props.preStop.delivery_floor);

    if (deliveryFloor.length > 0) {
      form.delivery_floor_option = deliveryFloor[0];
    } else if (form.housing_type.housingTypeCarrier.open_floor_input) {
      form.delivery_floor_option = null;
      form.select_other_delivery_floor = true;
      form.custom_delivery_floor = props.preStop.delivery_floor;
    }
  }
});

const previous = () => {
  if (currentStepIndex.value > 0) {
    currentStepIndex.value--;
  }
};

const next = () => {
  if (currentStepIndex.value + 1 < props.steps.length) {
    currentStepIndex.value++;
  } else {
    if (form.create_payment_link || form.do_pay) {
      window.onbeforeunload = null;
    }

    form
      .transform((data) => {
        data.housing_type_id = data.housing_type?.id ?? null;
        data.entrance_floor_option_id = data.entrance_floor_option?.id ?? null;
        data.delivery_floor_option_id = data.delivery_floor_option?.id ?? null;
        let serviceQuestions = {};
        for (const serviceQuestionId of Object.keys(data.service_questions)) {
          if (data.service_questions[serviceQuestionId].value) {
            serviceQuestions[serviceQuestionId] = data.service_questions[serviceQuestionId].value;
          }
        }
        data.service_questions = serviceQuestions;

        delete data.week_offset;
        delete data.time_window_id;
        delete data.housing_type;
        delete data.entrance_floor_option;
        delete data.select_other_entrance_floor;
        delete data.delivery_floor_option;
        delete data.select_other_delivery_floor;
        delete data.confirmed;
        delete data.date_costs;
        delete data.housing_type_floor_options_costs;
        delete data.floor_addition_weight_costs;
        delete data.service_question_costs;
        delete data.cash_on_delivery_amount_left_to_pay;
        delete data.service_amount_left_to_pay;
        delete data.already_paid;

        return data;
      })
      .submit('post', route('frontend.delivery-planner.store', props.preStop.uuid));
  }
};

provide('preStop', props.preStop);
provide('settings', props.settings);
provide('form', form);
provide('costs', costs);

onMounted(() => {
  window.onbeforeunload = function() {
    return true;
  };

  if (props.preStop.force_confirmation) {
    const housingTypeStep = props.steps.filter(step => step.key === 'housing_type')[0];

    form.housing_type = housingTypeStep.options.housingTypes[0];

    // skip to last step
    currentStepIndex.value = props.steps.length - 1;
  }
});
</script>
