<template>
  <div :style="style">
    <slot />

    <Translations />
  </div>
</template>

<script setup>
import {ref, watch} from 'vue';
import {usePage} from '@inertiajs/vue3';
import Translations from '@global/js/Components/Translations.vue';

const page = usePage();

const style = ref({});

watch(
  () => page.props.customLayout,
  () => {
    style.value = {};
    if (page.props.customLayout?.customColor) {
      style.value = {
        '--custom-layout-color': `#${page.props.customLayout.customColor}`,
      };
    }
  },
  { immediate: true },
);
</script>
