<template>
  <div class="mt-4 border-blue-600 border rounded-md">
    <div class="bg-blue-600 text-white text-lg font-medium text-center py-2">
      {{ __(`frontend.delivery_planner.steps.date.title.${preStop.translation_key}`) }}
    </div>
    <div class="p-4 space-y-2">
      <span v-if="!importedWithDeliveryDates">
        <span class="text-black block text-center text-sm !mb-2 italic">
          {{ __('frontend.delivery_planner.steps.date.week_number', {week: weekNumber > 0 ? weekNumber : null}) }}
        </span>

        <div class="flex text-sm">
          <div class="w-1/2">
            <button
              type="button"
              :class="[previousWeekEnabled ? 'text-blue-600 underline hover:no-underline' : 'text-gray-300 no-underline cursor-not-allowed']"
              @click="previousWeek"
            >
              {{ __('frontend.delivery_planner.steps.date.previous_week') }}
            </button>
          </div>

          <div class="w-1/2 text-right ">
            <button
              type="button"
              class="text-blue-600 underline hover:no-underline"
              @click="nextWeek"
            >
              {{ __('frontend.delivery_planner.steps.date.next_week') }}
            </button>
          </div>
        </div>
      </span>

      <div
        v-if="hasAvailableTimeWindows && !loading"
        class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-2"
      >
        <div
          v-for="date in dates"
          :key="date.day"
          class="bg-gray-100 text-black p-4 rounded-md block"
          :class="importedWithDeliveryDates ? 'border-2 border-red-500' : ''"
        >
          <span
            class="block font-bold"
            :class="date.timeWindows.length ? 'text-blue-600' : 'text-gray-400'"
          >{{ date.day }}</span>

          <div
            v-if="date.timeWindows.length && !importedWithDeliveryDates"
            class="grid grid-cols-1"
          >
            <span
              v-for="(time, index) in date.timeWindows"
              :key="index"
              class="text-sm mt-1 inline-flex"
            >
              <label
                class="hover:cursor-pointer"
                @click="selectTime(time)"
              >
                <input
                  v-model="form.time_window_id"
                  name="time[]"
                  type="radio"
                  class="-mt-1 mr-1"
                  :value="time.uuid"
                  @change="selectTime(time)"
                >
                <span class="text-blue-600">{{ time.from_formatted }} - {{ time.to_formatted }}</span>
                <span
                  v-if="time.price"
                  class="text-green"
                >
                  + {{ formatMoney(Decimal.fromString(time.price)) }}
                </span>
              </label>

              <Tooltip
                v-if="time.information"
                class="ml-2"
              >
                <template #trigger>
                  <i class="fas fa-question-circle text-blue-600 ml-1" />
                </template>
                <template #content>
                  <p class="w-full">
                    {{ time.information }}
                  </p>
                </template>
              </Tooltip>
            </span>
          </div>
          <div v-else-if="!importedWithDeliveryDates">
            <span class="block text-sm mt-1 text-gray-400"> {{ __('frontend.delivery_planner.steps.date.no_time_windows_available') }} </span>
          </div>
          <p
            v-else
            class="text-sm py-2 text-blue-600"
          >
            {{ __('frontend.delivery_planner.steps.date.fixed_date') }}
          </p>
        </div>
      </div>
      <div
        v-else-if="!loading"
        class="text-center py-2 text-gray-400 font-bold"
      >
        {{ __('frontend.delivery_planner.steps.date.no_dates_available') }}
      </div>
      <div
        v-else
        class="text-center py-2 text-gray-400 font-bold"
      >
        {{ __('frontend.delivery_planner.steps.date.loading') }}
      </div>
    </div>
  </div>

  <Buttons
    :validated="validated"
    :next="next"
  />
</template>

<script setup>
import {computed, defineEmits, inject, onMounted, ref, watch} from 'vue';
import Buttons from '@frontend/js/Components/DeliveryPlanner/Buttons.vue';
import axios from 'axios';
import Tooltip from '@frontend/js/Components/Tooltip.vue';
import {formatMoney} from '@global/js/helpers';
import Decimal from '@agrora/decimal';

const emit = defineEmits(['help', 'previous', 'next']);

const preStop = inject('preStop');
const form = inject('form');

const weekNumber = ref(0);
const dates = ref([]);
const loading = ref(false);
const initialLoading = ref(true);
const hasAvailableTimeWindows = ref(false);
const importedWithDeliveryDates = ref(preStop.imported_with_delivery_dates);

const validated = computed(() => {
  return !!form.eta_from && !!form.eta_to;
});

const previousWeekEnabled = computed(() => {
  return form.week_offset > 0;
});

const fetchDates = (weekOffset) => {
  if (loading.value) {
    return;
  }
  dates.value = null;
  loading.value = true;

  axios.get(route('frontend.api.delivery-planner.dates', {
    pre_stop: preStop.uuid,
    week_offset: weekOffset,
    language: route().params.language,
  }))
    .then((response) => {
      dates.value = response.data.dates;
      weekNumber.value = response.data.week;
      hasAvailableTimeWindows.value = response.data.has_available_time_windows;
      loading.value = false;

      if (
        initialLoading.value
        && !hasAvailableTimeWindows.value
        && form.week_offset < 4
      ) {
        form.week_offset++;
      } else {
        initialLoading.value = false;
      }

      if (preStop.eta_from && preStop.eta_to && !importedWithDeliveryDates.value && hasAvailableTimeWindows.value) {
        for (const date of dates.value) {
          let selected = date.timeWindows.find(time => time.from === preStop.eta_from && time.to === preStop.eta_to);

          if (selected) {
            selectTime(selected);

            break;
          }
        }

        return;
      }

      if (importedWithDeliveryDates.value) {
        form.eta_from = preStop.eta_from;
        form.eta_to = preStop.eta_to;
      }
    });
};

watch(() => form.week_offset, (value) => {
  fetchDates(value);
});

onMounted(() => {
  initialLoading.value = true;
  fetchDates(form.week_offset);
});

const next = () => {
  emit('next');
};

const previousWeek = () => {
  if (previousWeekEnabled.value) {
    form.week_offset--;
  }
};

const nextWeek = () => {
  form.week_offset++;
};

const selectTime = (time) => {
  if (importedWithDeliveryDates.value) {
    return;
  }

  form.eta_from = time.from;
  form.eta_to = time.to;
  form.time_window = time;
  form.time_window_id = time.uuid;
};
</script>
