<template>
  <Head :title="__('frontend.surveys.title')" />

  <Container>
    <div class="bg-gray-100 p-6 rounded-md">
      <HeaderLogo classes="w-full max-w-md mx-auto pb-2" />

      <div v-if="!survey.completed">
        <p class="text-center text-gray-300">
          {{ __('frontend.surveys.survey') }}
        </p>

        <form
          @submit.prevent="form.patch(route('frontend.surveys.complete', survey.id))"
        >
          <div
            class="mt-6 shadow space-y-4 bg-white p-2 grid divide-y md:px-4"
          >
            <div
              v-for="question in survey.questions"
              :key="question.id"
              class="text-center py-4"
            >
              <p class="font-bold">
                {{ question.question }}
              </p>

              <div v-if="question.type === 'score'">
                <p class="text-gray-300">
                  {{ scoreMin }} = {{ question.explanation_worst }}, {{ scoreMax }} = {{ question.explanation_best }}
                </p>
                <div class="grid grid-cols-10 justify-center mt-2">
                  <div
                    v-for="i in scoreRange"
                    :key="i"
                  >
                    <input
                      :id="i"
                      v-model="form.questions[question.id]"
                      type="radio"
                      :value="i"
                      :required="true"
                      :name="question.id"
                    >

                    <p
                      class="mt-1"
                      :class="$page.props.errors?.['questions.' + question.id] ? 'text-red' : ''"
                    >
                      {{ i }}
                    </p>
                  </div>
                </div>
              </div>
              <div v-else-if="question.type === 'open'">
                <div class="sm:px-1 md:px-8 py-2">
                  <textarea
                    v-model="form.questions[question.id]"
                    type="text"
                    :name="question.id"
                    class="block w-full rounded-md border-0 pl-3 text-black ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-blue"
                    :required="true"
                    :class="$page.props.errors?.['questions.' + question.id] ? 'border-red ring-red text-red placeholder-red focus:outline-none focus:ring-red focus:border-red' : 'focus:ring-gray-300 focus:border-gray-300'"
                  />
                </div>
              </div>

              <p
                v-if="$page.props.errors?.['questions.' + question.id]"
                class="mt-1 text-sm text-red"
                v-text="error('questions.' + question.id)"
              />
            </div>

            <div class="w-full pt-4">
              <button
                type="submit"
                class="w-full block rounded-md text-white py-3 text-center"
                :class="{
                  '!bg-custom-layout-color': $page.props.customLayout?.customColor,
                  'bg-orange hover:bg-orange/90': !$page.props.customLayout?.customColor,
                }"
              >
                {{ __('frontend.surveys.submit') }}
                <i
                  class="fas fa-chevron-right ml-2 inline w-2 -mt-0.5"
                  aria-hidden="true"
                />
              </button>
            </div>
          </div>
        </form>
      </div>
      <div v-else>
        <p class="bg-green border-green-500 bg-opacity-25 p-4 rounded-md border-2 text-center mt-8 font-medium text-green-900">
          {{ __('frontend.surveys.completed') }}
        </p>
      </div>
    </div>
  </Container>
</template>

<script setup>
import {defineProps, ref} from 'vue';
import {Head, useForm, usePage} from '@inertiajs/vue3';
import Container from '@frontend/js/Components/Container.vue';
import HeaderLogo from '@frontend/js/Components/HeaderLogo.vue';

const props = defineProps({
  survey: {
    type: Object,
    required: true,
  },
});

const range = (min, max) => {
  return Array.from({length: max - min + 1}, (_, i) => min + i);
};

const scoreMin = ref(1);
const scoreMax = ref(10);
const scoreRange = ref(range(scoreMin.value, scoreMax.value));

const questions = {};

for (const question of props.survey.questions) {
  questions[question.id] = null;
}

const form = useForm({
  questions,
});

const $page = usePage();

const error = (key) => {
  return $page.props.errors?.[key]?.replace(key + ' ', '');
};
</script>
