<template>
  <div class="mt-8 md:mt-4 border-gray-200 border rounded-md overflow-hidden text-sm">
    <div class="font-bold text-base px-4 py-2 bg-gray-100 text-black">
      {{ __('frontend.delivery_planner.sidebar.title') }}
    </div>

    <div
      v-if="preStop.reference"
      class="border-t border-gray-200 flex flex-wrap px-4 py-2"
    >
      <div class="w-1/3 pr-2 break-words">
        {{ __('frontend.delivery_planner.sidebar.reference') }}
      </div>

      <div class="w-2/3 break-words">
        {{ preStop.reference }}
      </div>
    </div>

    <div
      v-if="preStop.recipient_name"
      class="border-t border-gray-200 flex flex-wrap px-4 py-2"
    >
      <div class="w-1/3 pr-2 break-words">
        {{ __('frontend.delivery_planner.sidebar.recipient_name') }}
      </div>

      <div class="w-2/3 break-words">
        {{ preStop.recipient_name }}
      </div>
    </div>

    <div class="border-t border-gray-200 flex flex-wrap px-4 py-2">
      <div class="w-1/3 pr-2 break-words">
        {{ __('frontend.delivery_planner.sidebar.address') }}
      </div>

      <div class="w-2/3 break-words">
        {{ formattedAddress() }}

        <div
          v-if="formattedRemarks()"
          class="mt-1"
        >
          <i>{{ formattedRemarks() }}</i>
        </div>
      </div>
    </div>

    <div
      v-if="preStop.external_reference"
      class="border-t border-gray-200 flex flex-wrap px-4 py-2"
    >
      <div class="w-1/3 pr-4 break-words">
        {{ __('frontend.delivery_planner.sidebar.external_reference') }}
      </div>

      <div class="w-2/3 break-words">
        {{ preStop.external_reference }}
      </div>
    </div>

    <div
      v-if="preStop.shipment_lines"
      class="border-t border-gray-200 flex flex-wrap px-4 py-2"
    >
      <div class="w-1/3 pr-4 break-words">
        {{ __('frontend.delivery_planner.sidebar.shipment_lines') }}
      </div>

      <div
        class="w-2/3 break-words cursor-pointer"
        @click="openShipmentLineModal(
          __('frontend.delivery_planner.sidebar.shipment_lines_modal_title'),
          preStop.shipment_lines
        )"
      >
        <template
          v-for="i of 3"
          :key="i"
        >
          <div v-if="preStop.shipment_lines[i - 1]">
            {{ preStop.shipment_lines[i - 1].quantity }}x {{ preStop.shipment_lines[i - 1].description }}
          </div>
        </template>
        <div v-if="preStop.shipment_lines.length > 3">
          ... <i class="far fa-info-circle" />
        </div>
      </div>
    </div>

    <div
      v-if="preStop.exchange_lines"
      class="border-t border-gray-200 flex flex-wrap px-4 py-2"
    >
      <div class="w-1/3 pr-4 break-words">
        {{ __('frontend.delivery_planner.sidebar.exchange_lines') }}
      </div>
      <div
        class="w-2/3 break-words cursor-pointer"
        @click="openShipmentLineModal(
          __('frontend.delivery_planner.sidebar.exchange_lines_modal_title'),
          preStop.exchange_lines
        )"
      >
        <template
          v-for="i of 3"
          :key="i"
        >
          <div v-if="preStop.exchange_lines[i - 1]">
            {{ preStop.exchange_lines[i - 1].quantity }}x {{ preStop.exchange_lines[i - 1].description }}
          </div>
        </template>
        <div v-if="preStop.exchange_lines.length > 3">
          ... <i class="far fa-info-circle" />
        </div>
      </div>
    </div>

    <div
      v-if="preStop.total_weight > 0"
      class="border-t border-gray-200 flex flex-wrap px-4 py-2"
    >
      <div class="w-1/3 pr-4 break-words">
        {{ __('frontend.delivery_planner.sidebar.weight') }}
      </div>

      <div class="w-2/3 break-words">
        {{ preStop.total_weight_formatted }} kg
      </div>
    </div>

    <div class="border-t border-gray-200 flex flex-wrap px-4 py-2">
      <div class="w-1/3 pr-4 break-words">
        <input
          v-model="preStop.notification_phone_enabled"
          class="hover:cursor-not-allowed"
          type="checkbox"
          :disabled="true"
        >
        {{ __('frontend.delivery_planner.sidebar.sms_notification') }}
      </div>

      <div class="w-2/3 break-words">
        {{ preStop.notification_phone }}
      </div>
    </div>

    <div class="border-t border-gray-200 flex flex-wrap px-4 py-2">
      <div class="w-1/3 pr-4 break-words">
        <input
          v-model="preStop.notification_email_enabled"
          class="hover:cursor-not-allowed"
          type="checkbox"
          :disabled="true"
        >
        {{ __('frontend.delivery_planner.sidebar.email_notification') }}
      </div>

      <div class="w-2/3 break-words">
        {{ preStop.notification_email }}
      </div>
    </div>

    <div class="border-t border-gray-200 flex flex-wrap px-4 py-2">
      <div class="w-1/3 pr-4 break-words">
        {{ __(`frontend.delivery_planner.eta.${preStop.translation_key}`) }}
      </div>

      <!-- eslint-disable vue/no-v-html -->
      <div
        class="w-2/3 break-words"
        v-html="formattedEta()"
      />
      <!-- eslint-enable -->
    </div>


    <div
      v-if="!cashOnDeliveryAmountLeftToPay.isZero()"
      class="border-t border-gray-200 flex flex-wrap px-4 py-2"
    >
      <div class="w-1/3 pr-4 break-words">
        {{ __('frontend.delivery_planner.sidebar.cash_on_delivery_amount') }}
      </div>

      <div class="w-2/3 break-words">
        {{ formatMoney(cashOnDeliveryAmountLeftToPay) }}
      </div>
    </div>

    <div
      v-if="!serviceAmountLeftToPay.isZero()"
      class="border-t border-gray-200 flex flex-wrap px-4 py-2"
    >
      <div class="w-1/3 pr-4 break-words">
        {{ __('frontend.delivery_planner.sidebar.service_amount') }}
      </div>

      <div class="w-2/3 break-words">
        {{ formatMoney(serviceAmountLeftToPay) }}
      </div>
    </div>

    <div
      v-if="formattedHousingType()"
      class="border-t border-gray-200 flex flex-wrap px-4 py-2"
    >
      <div class="w-1/3 pr-4 break-words">
        {{ __('frontend.delivery_planner.sidebar.housing_type') }}
      </div>

      <!-- eslint-disable vue/no-v-html -->
      <div
        class="w-2/3 break-words"
        v-html="formattedHousingType()"
      />
      <!-- eslint-enable -->
    </div>

    <div
      v-if="services.length"
      class="border-t border-gray-200 flex flex-wrap px-4 py-2"
    >
      <div class="w-1/3 pr-4 break-words">
        {{ __('frontend.delivery_planner.sidebar.services') }}
      </div>

      <div class="w-2/3 break-words">
        <!-- eslint-disable vue/no-v-html -->
        <div
          v-for="(formattedDeliveryService, index) in services"
          :key="index"
          v-html="formattedDeliveryService"
        />
        <!-- eslint-enable -->
      </div>
    </div>
  </div>

  <Link
    v-if="preStop.canReschedule"
    :href="route('frontend.delivery-planner.schedule', {pre_stop: preStop.uuid, reschedule: ''})"
    type="button"
    class="bg-blue-600 text-white py-1.5 px-3 text-xs inline-block mt-2 rounded-md ml-auto hover:bg-blue-600/70"
  >
    {{ __('frontend.delivery_planner.reschedule') }}
  </Link>

  <ShipmentLinesModal
    v-if="showShipmentLineModal"
    :title="shipmentLineModalTitle"
    :lines="shipmentLineModalLines"
    @close="showShipmentLineModal = false"
  />
</template>

<script setup>
import {defineProps, inject, ref} from 'vue';
import {__, formatMoney} from '@global/js/helpers';
import ShipmentLinesModal from '@frontend/js/Components/DeliveryPlanner/Modals/ShipmentLinesModal.vue';
import moment from 'moment/moment';
import Decimal from '@agrora/decimal';

defineProps({
  services: {
    type: Array,
    required: false,
    default: () => [],
  },
});

const preStop = inject('preStop');

const showShipmentLineModal = ref(false);
const shipmentLineModalTitle = ref('');
const shipmentLineModalLines = ref([]);
const cashOnDeliveryAmountLeftToPay = ref(Decimal.fromString(preStop.cash_on_delivery_amount_left_to_pay ?? '0.00'));
const serviceAmountLeftToPay = ref(Decimal.fromString(preStop.total_service_costs_left_to_pay ?? '0.00'));

const formattedAddress = () => {
  let streetWithNumberAndAddition = [preStop.street, preStop.house_no, preStop.address_addition]
    .filter(v => v?.length)
    .join(' ');

  return [streetWithNumberAndAddition, preStop.postcode, preStop.city]
    .filter(v => v?.length)
    .join(', ');
};

const formattedRemarks = () => {
  if (!preStop.remarks) {
    return null;
  }

  if (preStop.remarks.length <= 50) {
    return preStop.remarks;
  }

  return preStop.remarks.slice(0, 50) + '...';
};

const formattedHousingType = () => {
  if (!preStop.housing_type) {
    return null;
  }

  let text = preStop.housing_type.name;

  if (preStop.entrance_floor_option) {
    text += `, ${preStop.entrance_floor_option.name}`;
  } else if (preStop.custom_entrance_floor) {
    text += `, ${__('frontend.delivery_planner.sidebar.entrance_floor')}: ${preStop.custom_entrance_floor}`;
  } else if (preStop.floor > 0) {
    text += `, ${__('frontend.delivery_planner.sidebar.entrance_floor')}: ${preStop.floor}`;
  }

  if (preStop.delivery_floor_option) {
    text += `, ${preStop.delivery_floor_option.name}`;
  } else if (preStop.custom_delivery_floor) {
    text += `, ${__('frontend.delivery_planner.sidebar.delivery_floor')}: ${preStop.custom_delivery_floor}`;
  } else if (preStop.delivery_floor > 0) {
    text += `, ${__('frontend.delivery_planner.sidebar.delivery_floor')}: ${preStop.delivery_floor}`;
  }

  let floorCosts = Decimal.fromString('0.00');
  if (preStop.extra_floor_costs) {
    floorCosts = Decimal.fromString(preStop.extra_floor_costs);
  }
  if (!floorCosts.isZero()) {
    text += ` (<strong>+ ${formatMoney(floorCosts)}</strong>)`;
  }

  return text;
};

const formattedEta = () => {
  let day = moment(preStop.eta_from).format('dddd D MMMM');
  day = day.charAt(0).toUpperCase() + day.slice(1);

  const from = moment(preStop.eta_from).format('HH:mm');
  const to = moment(preStop.eta_to).format('HH:mm');

  let formatted = `${day}, ${from} - ${to}`;
  if (from === '00:00' && to === '00:00') {
    formatted = day;
  }

  let dateCostAmount = Decimal.fromString('0.00');
  if (preStop.cost_date_amount) {
    dateCostAmount = Decimal.fromString(preStop.cost_date_amount);
  }

  if (!dateCostAmount.isZero()) {
    return `${formatted} (<strong>+ ${formatMoney(dateCostAmount)}</strong>)`;
  }

  return formatted;
};

const openShipmentLineModal = (title, lines) => {
  showShipmentLineModal.value = true;
  shipmentLineModalTitle.value = title;
  shipmentLineModalLines.value = lines;
};
</script>
