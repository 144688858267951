<template>
  <section
    v-if="sliderEnabled"
    class="hidden lg:block lg:fixed left-0 bottom-0 top-0 bg-white/50 z-20 fixed"
  >
    <div class="w-[200px] px-4 grayscale flex flex-col gap-6">
      <VueMarqueeSlider
        id="marquee-slider"
        :speed="5000 * images.length"
        :height="200"
        :space="25"
        :repeat="0"
        :vertical="true"
        :paused="paused"
        @mouseover="paused = true"
        @mouseleave="paused = false"
      >
        <img
          v-for="(image, index) in images"
          :key="index"
          :src="image"
          loading="lazy"
        >
      </VueMarqueeSlider>
    </div>
  </section>
</template>

<script setup>
import {computed, defineProps, ref} from 'vue';
import { VueMarqueeSlider } from 'vue3-marquee-slider';
import {usePage} from '@inertiajs/vue3';

const props = defineProps({
  sliderImages: {
    type: Array,
    required: true,
  },
});

const paused = ref(false);
const images = ref([]);
while (images.value.length < 30) {
  for (const image of props.sliderImages) {
    images.value.push(image);
  }
}

const $page = usePage();

const sliderEnabled = computed(() => {
  const hideSlider = $page.props.customLayout?.hideSlider ?? false;
  return !hideSlider;
});
</script>
