<template>
  <div
    class="relative z-50"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
  >
    <div class="fixed inset-0 bg-black bg-opacity-75 transition-opacity" />

    <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
      <div
        class="flex min-h-full justify-center p-4 text-center items-center"
        @click="close"
      >
        <div
          class="relative transform overflow-hidden rounded-lg bg-white p-6 text-left max-w-[500px]"
          @click.stop
        >
          <div
            class="absolute top-4 right-4 hover:cursor-pointer"
            @click="close"
          >
            <i class="fa fa-times" />
          </div>

          <h3 class="text-2xl text-center font-bold">
            {{ __('frontend.delivery_planner.modals.address.title') }}
          </h3>

          <form
            class="space-y-3 mt-4 min-w-[300px]"
            @submit.prevent="close"
          >
            <div class="w-full">
              <label
                class="block text-xs text-gray-300"
              >
                {{ __('frontend.delivery_planner.modals.address.fields.country') }}
              </label>
              <div class="mt-0.5">
                {{ preStop.country_name }}
              </div>
            </div>

            <div class="w-full">
              <label
                for="city"
                class="block text-xs text-gray-300"
              >
                {{ __('frontend.delivery_planner.modals.address.fields.city') }}
              </label>
              <div class="mt-0.5">
                <input
                  v-model="form.city"
                  type="text"
                  name="city"
                  class="block w-full rounded-md border-0 py-2 pl-3 text-black ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-blue"
                >
              </div>
            </div>

            <div class="w-full">
              <label
                for="postcode"
                class="block text-xs text-gray-300"
              >
                {{ __('frontend.delivery_planner.modals.address.fields.postcode') }}
              </label>
              <div class="mt-0.5">
                <input
                  v-model="form.postcode"
                  type="text"
                  name="postcode"
                  class="block w-full rounded-md border-0 py-2 pl-3 text-black ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-blue"
                >
              </div>
            </div>

            <div class="w-full">
              <label
                for="street"
                class="block text-xs text-gray-300"
              >
                {{ __('frontend.delivery_planner.modals.address.fields.street') }}
              </label>
              <div class="mt-0.5">
                <input
                  v-model="form.street"
                  type="text"
                  name="street"
                  class="block w-full rounded-md border-0 py-2 pl-3 text-black ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-blue"
                >
              </div>
            </div>

            <div class="w-full">
              <label
                for="house_no"
                class="block text-xs text-gray-300"
              >
                {{ __('frontend.delivery_planner.modals.address.fields.house_no') }}
              </label>
              <div class="mt-0.5">
                <input
                  v-model="form.house_no"
                  type="text"
                  name="house_no"
                  class="block w-full rounded-md border-0 py-2 pl-3 text-black ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-blue"
                >
              </div>
            </div>

            <div class="w-full">
              <label
                for="address_addition"
                class="block text-xs text-gray-300"
              >
                {{ __('frontend.delivery_planner.modals.address.fields.address_addition') }}
              </label>
              <div class="mt-0.5">
                <input
                  v-model="form.address_addition"
                  type="text"
                  name="address_addition"
                  class="block w-full rounded-md border-0 py-2 pl-3 text-black ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-blue"
                >
              </div>
            </div>

            <div
              v-if="settings.allowRemark"
              class="w-full"
            >
              <label
                for="remarks"
                class="block text-xs text-gray-300"
              >
                {{ __('frontend.delivery_planner.modals.address.fields.remarks') }}
              </label>
              <div class="mt-0.5">
                <textarea
                  v-model="form.remarks"
                  rows="5"
                  type="text"
                  name="remarks"
                  class="block w-full rounded-md border-0 py-2 pl-3 text-black ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-blue"
                />
              </div>
            </div>

            <div class="w-full">
              <button
                type="submit"
                class="w-full block rounded-md bg-orange text-white py-3 text-center hover:bg-orange/90"
              >
                {{ __('frontend.delivery_planner.modals.address.save') }}
                <i
                  class="fas fa-chevron-right ml-2 inline w-2 -mt-0.5"
                  aria-hidden="true"
                />
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {defineEmits, inject} from 'vue';

const emit = defineEmits(['close']);

const close = () => emit('close');

const settings = inject('settings');
const preStop = inject('preStop');
const form = inject('form');
</script>
