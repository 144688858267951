<template>
  <Head :title="__('frontend.index.title')" />

  <Container
    :wider="true"
    :show-back-button="true"
  >
    <div class="bg-gray-100 p-6 rounded-md">
      <HeaderLogo classes="w-full max-w-md mx-auto" />

      <div class="mt-6 space-y-4">
        <div v-if="stop.tracking_image">
          <div v-if="stop.stops_left !== null">
            <template v-if="stop.stops_left === 0">
              <img
                src="@global/assets/media/stop/progress-trip-next.png"
                class="w-full lg:w-3/5 lg:mx-auto"
              >
              <p class="text-center">
                {{ __('frontend.stop.you_are_next') }}
              </p>
            </template>

            <template v-else>
              <div class="relative">
                <img
                  src="@global/assets/media/stop/progress-trip.png"
                  class="w-full lg:w-3/5 lg:mx-auto"
                >
                <span class="text-white stops-left-placeholder">
                  <span
                    v-if="stop.stops_left > 9"
                    class="relative -left-1 text-sm"
                  >
                    {{ stop.stops_left }}
                  </span>
                  <span v-else>{{ stop.stops_left }}</span>
                </span>
              </div>
              <p class="text-center">
                {{ __('frontend.stop.stops_to_go', {count: stop.stops_left}) }}
              </p>
            </template>
          </div>

          <img
            :src="stop.tracking_image"
            class="w-full rounded-md lg:w-5/6 lg:mx-auto"
          >
        </div>

        <div class="flex flex-wrap space-y-3">
          <div class="flex w-full space-x-4 items-center lg:w-1/2 pl-1">
            <div class="w-1/6">
              <img
                v-if="hasAddress"
                src="@global/assets/media/stop/house.svg"
                class="w-full"
              >
              <img
                v-else
                src="@global/assets/media/stop/tags.svg"
                class="w-full"
              >
            </div>

            <div class="w-5/6">
              <span v-if="hasAddress">
                <span class="block text-sm text-gray-300">{{ __('frontend.stop.address') }}:</span>
                <span class="font-bold text-xl block">{{ stop.postcode }} / {{ stop.house_no }}</span>
                <span v-if="stop.address_addition">{{ stop.address_addition }}</span>
              </span>
              <span v-else>
                <span class="block text-sm text-gray-300">{{ __('frontend.stop.reference') }}:</span>
                <span class="font-bold text-xl block">{{ stop.reference }}</span>
              </span>
            </div>
          </div>

          <div class="h-1 border-t border-gray-200 w-full lg:hidden" />

          <div class="flex w-full space-x-4 items-center lg:w-1/2 pl-4">
            <div class="w-1/6">
              <DeliveryTypeImage :type="stop.type" />
            </div>

            <div class="w-5/6">
              <div class="w-2/3">
                <span class="block text-sm text-gray-300">
                  <span v-if="stop.show_window">{{ __('frontend.stop.expected_between') }}:</span>
                  <span v-else>{{ __('frontend.stop.expected_at') }}:</span>
                </span>

                <span class="font-bold text-xl block">{{ stop.eta_day }}: {{ stop.eta }}</span>

                <div
                  v-if="stop.completed_at"
                  :class="{
                    'text-green': !stop.has_deviation_code,
                    'text-red': stop.has_deviation_code,
                  }"
                >
                  <span class="block text-sm">
                    {{ __('frontend.stop.completed_at') }}:
                  </span>
                  <span class="font-bold text-xl block">{{ stop.completed_at_day }}: {{ stop.completed_at }}</span>
                  <span
                    v-if="stop.has_deviation_code"
                  >
                    {{ stop.deviation_code.description }}
                  </span>
                </div>

                <span
                  v-else-if="stop.exact_eta"
                  class="text-orange"
                >
                  ({{ __('frontend.stop.estimated_time') }} {{ stop.exact_eta }})
                </span>
              </div>

              <div class="w-1/3">
                <i
                  v-if="stop.completed_at"
                  class="fas fa-check fa-2x"
                  aria-hidden="true"
                  :class="{
                    'text-green': !stop.has_deviation_code,
                    'text-red': stop.has_deviation_code,
                  }"
                />
              </div>
            </div>
          </div>

          <div class="h-1 border-t border-gray-200 w-full" />

          <div class="flex w-full space-x-4 items-center lg:w-1/2 pl-1">
            <div class="w-1/6">
              <img
                src="@global/assets/media/stop/store.svg"
                class="w-full"
              >
            </div>

            <div class="w-5/6">
              <div class="flex items-center gap-2">
                <div
                  class="w-1/2"
                  :class="{
                    'break-all': !!stop.vendor_logo,
                  }"
                >
                  <span class="block text-sm text-gray-300">{{ __('frontend.stop.vendor') }}:</span>
                  <span class="font-bold text-xl block">{{ stop.vendor_name }}</span>
                </div>

                <div class="w-1/2">
                  <img
                    v-if="stop.vendor_logo"
                    :src="stop.vendor_logo"
                    class="w-full lg:pr-4"
                  >
                </div>
              </div>
            </div>
          </div>

          <div class="h-1 border-t border-gray-200 w-full lg:hidden" />

          <div class="flex w-full space-x-4 items-center lg:w-1/2 pl-4">
            <div class="w-1/6">
              <img
                src="@global/assets/media/stop/truck.svg"
                class="w-full"
              >
            </div>

            <div class="w-5/6">
              <div class="flex items-center gap-2">
                <div
                  class="w-2/3"
                  :class="{
                    'break-all': !!stop.carrier_logo,
                  }"
                >
                  <span class="block text-sm text-gray-300">{{ __('frontend.stop.carrier') }}:</span>
                  <span class="font-bold text-xl block">{{ stop.carrier_name }}</span>
                </div>

                <div class="w-1/3">
                  <img
                    v-if="stop.carrier_logo"
                    :src="stop.carrier_logo"
                    class="w-full lg:pr-4"
                  >
                </div>
              </div>
            </div>
          </div>

          <div class="h-1 border-t border-gray-200 w-full" />
        </div>
      </div>

      <div
        v-if="stop.email_enabled || stop.phone_enabled || ((!hasEmail || !hasPhone) && stop.show_disabled_notification_settings)"
        class="mt-6"
      >
        <NotificationPanel
          :stop-id="stop.id"
          :phone-enabled="stop.phone_enabled"
          :email-enabled="stop.email_enabled"
          :should-show-disabled="stop.show_disabled_notification_settings"
          :has-email="hasEmail"
          :has-phone="hasPhone"
          :notification-minutes-prior="stop.notification_minutes_prior"
          @email-deleted="hasEmail = false"
          @email-updated="hasEmail = true"
          @phone-deleted="hasPhone = false"
          @phone-updated="hasPhone = true"
        />
      </div>
    </div>
  </Container>
</template>

<script setup>
import {defineProps, ref} from 'vue';
import {Head} from '@inertiajs/vue3';
import Container from '@frontend/js/Components/Container.vue';
import DeliveryTypeImage from '@frontend/js/Components/DeliveryTypeImage.vue';
import NotificationPanel from '@frontend/js/Components/NotificationPanel.vue';
import HeaderLogo from '@frontend/js/Components/HeaderLogo.vue';

const props = defineProps({
  stop: {
    type: Object,
    required: true,
  },

  postcode: {
    type: [String, null],
    required: true,
  },

  houseNumber: {
    type: [String, null],
    required: true,
  },

  reference: {
    type: [String, null],
    required: true,
  },
});

const hasAddress = props.postcode && props.houseNumber && props.stop.postcode === props.postcode && props.houseNumber === props.stop.house_no;
const hasEmail = ref(props.stop.has_notification_email);
const hasPhone = ref(props.stop.has_notification_phone);
</script>

<style lang="scss" scoped>
.stops-left-placeholder {
  position: absolute;
  left: calc(50% + 0.80rem);
  top: 1.75rem;
}
</style>
