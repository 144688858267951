<template>
  <div class="mt-4 border-blue-600 border rounded-md overflow-hidden">
    <div class="bg-blue-600 text-white text-lg font-medium text-center py-2">
      {{ __(`frontend.delivery_planner.steps.confirm.title.${preStop.translation_key}`) }}
    </div>

    <div class="bg-gray-100 p-4">
      <span class="block text-center">
        {{ __(`frontend.delivery_planner.steps.confirm.subtitle.${preStop.translation_key}`) }}
      </span>

      <div class="space-y-2 mt-4">
        <div class="bg-white rounded-md p-4 shadow-md text-sm">
          <span class="block">
            <strong>{{ __(`frontend.delivery_planner.steps.confirm.address`) }}</strong> {{ formattedAddress }} </span>
        </div>

        <div
          v-if="preStop.shipment_lines?.length"
          class="bg-white rounded-md p-4 shadow-md text-sm"
        >
          <span class="block mb-1 font-bold">
            {{ __(`frontend.delivery_planner.steps.confirm.shipment_lines`) }}
          </span>
          <ul class="list-outside list-disc pl-4">
            <li
              v-for="(shipment_line, index) of preStop.shipment_lines"
              :key="index"
            >
              {{ shipment_line.quantity }}x {{ shipment_line.description }}
            </li>
          </ul>
        </div>

        <div class="bg-white rounded-md p-4 shadow-md text-sm">
          <span class="block mb-1 font-bold">
            {{ __(`frontend.delivery_planner.steps.confirm.services`) }}
          </span>
          <!-- eslint-disable vue/no-v-html -->
          <ul class="list-outside list-disc pl-4">
            <li v-if="formattedEta">
              {{ __(`frontend.delivery_planner.eta.${preStop.translation_key}`) }}: {{ formattedEta }} <span v-html="moneyFormat(costs.date_costs)" />
            </li>
            <template
              v-for="serviceQuestion of form.fixed_service_questions"
              :key="serviceQuestion.serviceQuestion.id"
            >
              <li v-if="serviceQuestion.value">
                <span v-if="serviceQuestion.serviceQuestion.type === 'quantity'">
                  {{ serviceQuestion.value }}x
                </span> {{ serviceQuestion.serviceQuestion.question }}
                <span v-html="formattedPrice(serviceQuestion)" />
              </li>
            </template>
            <template
              v-for="serviceQuestion of form.service_questions"
              :key="serviceQuestion.serviceQuestion.id"
            >
              <li v-if="serviceQuestion.value">
                <span v-if="serviceQuestion.serviceQuestion.type === 'quantity'">
                  {{ serviceQuestion.value }}x
                </span> {{ serviceQuestion.serviceQuestion.question }}
                <span v-html="formattedPrice(serviceQuestion)" />
              </li>
            </template>
          </ul>
          <!-- eslint-enable -->
        </div>

        <div
          v-if="settings.additionalQuestionsEnabled"
          class="bg-white rounded-md p-4 shadow-md text-sm"
        >
          <span class="block mb-1 font-bold">
            {{ __('frontend.delivery_planner.steps.confirm.additional_questions') }}
          </span>

          <ul class="list-outside list-disc pl-4">
            <template
              v-for="question of form.additional_questions"
              :key="question.id"
            >
              <li>
                <span>
                  {{ question.text }}
                </span>
                <span class="ml-2 italic">
                  {{ question.answer === true ? question.yes_text : question.no_text }}
                </span>
              </li>
            </template>
          </ul>
        </div>

        <template v-if="settings.paymentsEnabled">
          <div
            v-if="settings.cashOnDeliveryEnabled && !costs.cash_on_delivery_amount_left_to_pay.isZero()"
            class="bg-white rounded-md p-4 shadow-md text-sm md:flex md:flex-wrap md:justify-between"
          >
            <span>
              <input
                v-model="form.pay_cash_on_delivery"
                type="checkbox"
                class="mr-2 -mt-0.5 inline-block"
                :required="settings.cashOnDeliveryRequired"
                :disabled="settings.cashOnDeliveryRequired"
              >
              {{ __('frontend.delivery_planner.steps.confirm.cash_on_delivery_to_pay') }}
            </span>
            <span>{{ formatMoney(costs.cash_on_delivery_amount_left_to_pay) }}</span>
          </div>

          <div
            v-if="settings.additionalPaymentsEnabled && !costs.extra_cash_on_delivery_amount_left_to_pay.isZero()"
            class="bg-white rounded-md p-4 shadow-md text-sm md:flex md:flex-wrap md:justify-between"
          >
            <span>
              <input
                v-model="form.pay_additional"
                type="checkbox"
                class="mr-2 -mt-0.5 inline-block"
                :required="settings.additionalPaymentsRequired"
                :disabled="settings.additionalPaymentsRequired"
              >
              {{ __('frontend.delivery_planner.steps.confirm.additional_pay') }}
            </span>
            <span>{{ formatMoney(costs.extra_cash_on_delivery_amount_left_to_pay) }}</span>
          </div>

          <div class="bg-white rounded-md p-4 shadow-md">
            <div
              v-if="costs.cash_on_delivery_amount_left_to_pay.isGreaterThan(0)"
              class="text-sm md:flex md:flex-wrap md:justify-between"
            >
              <span>{{ __('frontend.delivery_planner.steps.confirm.total_amount_to_pay_later') }}</span>
              <span>{{ formatMoney(costs.cash_on_delivery_amount_left_to_pay) }}</span>
            </div>

            <div class="text-sm md:flex md:flex-wrap md:justify-between text-blue-600 font-bold">
              <span>{{ __('frontend.delivery_planner.steps.confirm.total_amount_to_pay') }}</span>
              <span>{{ formatMoney(costs.total_price) }}</span>
            </div>
          </div>

          <div
            v-if="!costs.already_paid.isZero()"
            class="bg-white rounded-md p-4 shadow-md text-sm md:flex md:flex-wrap md:justify-between text-blue-600 font-bold"
          >
            <span>{{ __('frontend.delivery_planner.steps.confirm.total_amount_already_paid') }}</span>
            <span>{{ formatMoney(costs.already_paid) }}</span>
          </div>

          <div
            v-if="!costs.total_price.isZero() && settings.cashOnDeliveryInformation"
            class="bg-white rounded-md p-4 shadow-md text-sm md:flex md:flex-wrap md:justify-between"
          >
            <span>{{ settings.cashOnDeliveryInformation }}</span>
          </div>
        </template>
      </div>
    </div>
  </div>

  <div class="mt-4">
    <label
      v-if="termsAndConditionsUrl"
      class="cursor-pointer"
    >
      <input
        v-model="form.confirmed"
        type="checkbox"
        class="mr-1 -mt-1 inline-block"
      >
      {{ __('frontend.delivery_planner.steps.confirm.confirm_and_terms') }}
      <a
        :href="termsAndConditionsUrl"
        target="_blank"
        class="text-blue-600 underline"
        rel="noopener"
      >
        {{ __('frontend.delivery_planner.steps.confirm.link_to_conditions') }}
      </a>
    </label>
    <label
      v-else
      class="cursor-pointer"
    >
      <input
        v-model="form.confirmed"
        type="checkbox"
        class="mr-1 -mt-1 inline-block"
      >
      {{ __('frontend.delivery_planner.steps.confirm.confirm') }}
    </label>
  </div>

  <Buttons
    :previous="previous"
    :validated="validated"
    :next="pay"
    :next-label="!costs.total_paying.isZero() ? __('frontend.delivery_planner.pay', {money: formatMoney(costs.total_paying)}) : __('frontend.delivery_planner.confirm')"
  >
    <template #after-next>
      <button
        v-if="settings.canCreatePaymentLink && !costs.total_paying.isZero()"
        type="button"
        class="w-full block rounded-md text-white mt-3 py-3 text-center"
        :class="{
          '!bg-custom-layout-color hover:opacity-90': validated && $page.props.customLayout?.customColor,
          '!bg-custom-layout-color cursor-not-allowed opacity-50': !validated && $page.props.customLayout?.customColor,
          'bg-green/50 cursor-not-allowed': !validated && !$page.props.customLayout?.customColor,
          'bg-green hover:bg-green/90': validated && !$page.props.customLayout?.customColor,
        }"
        :disabled="!validated"
        @click="paymentLink()"
      >
        {{ __('frontend.delivery_planner.payment-link') }}
        <i
          class="fas fa-chevron-right ml-2 inline w-2 -mt-0.5"
          aria-hidden="true"
        />
      </button>
    </template>
  </Buttons>
</template>

<script setup>
import {computed, defineEmits, defineProps, inject} from 'vue';
import Buttons from '@frontend/js/Components/DeliveryPlanner/Buttons.vue';
import moment from 'moment/moment';
import {formatMoney} from '@global/js/helpers';
import Decimal from '@agrora/decimal';

const preStop = inject('preStop');
const form = inject('form');
const costs = inject('costs');
const settings = inject('settings');

defineProps({
  termsAndConditionsUrl: {
    type: String,
    required: false,
    default: null,
  },
});

const emit = defineEmits(['help', 'previous', 'next']);

const validated = computed(() => {
  if (form.processing) {
    return false;
  }

  return form.confirmed;
});

const formattedAddress = computed(() => {
  let streetWithNumberAndAddition = [form.street, form.house_no, form.address_addition]
    .filter(v => v?.length)
    .join(' ');

  return [preStop.recipient_name, streetWithNumberAndAddition, form.postcode, form.city].filter(v => v?.length).join(', ');
});

const formattedEta = computed(() => {
  if (form.eta_from && form.eta_to) {
    let day = moment(form.eta_from).format('dddd D MMMM');
    day = day.charAt(0).toUpperCase() + day.slice(1);

    const from = moment(form.eta_from).format('HH:mm');
    const to = moment(form.eta_to).format('HH:mm');

    return `${day}, ${from} - ${to}`;
  }

  return null;
});

const formattedPrice = (answer) => {
  const question = answer.serviceQuestion;
  const price = Decimal.fromString(question.price ?? '0.00');

  if (question.type === 'checkbox') {
    return moneyFormat(price);
  } else if (question.type === 'quantity') {
    const amount = parseInt(answer.value);

    const total = price.multiply(amount);

    return moneyFormat(total);
  }

  return '';
};

const moneyFormat = (value) => {
  if (value.isZero()) {
    return '';
  }

  return ` (<strong>+ ${formatMoney(value)}</strong>)`;
};

const previous = () => {
  emit('previous');
};

const pay = () => {
  form.do_pay = true;
  emit('next');
};

const paymentLink = () => {
  form.create_payment_link = true;
  emit('next');
};
</script>

