<template>
  <Head :title="__('frontend.delivery_planner.title')" />

  <Container>
    <div class="bg-white p-6 rounded-md lg:p-8">
      <Header />

      <div class="flex flex-wrap xl:flex-nowrap xl:space-x-4">
        <div class="w-full xl:w-2/3">
          <div class="mt-4 border-blue-600 border rounded-md overflow-hidden">
            <div class="bg-blue-600 text-white text-lg font-mediun text-center py-2">
              {{ __(`frontend.delivery_planner.success.title.${preStop.translation_key}`) }}
            </div>

            <div class="bg-gray-100 p-4">
              <span class="block text-center font-bold">
                {{ __('frontend.delivery_planner.success.completed_at') }}: {{ preStop.scheduled_at }}
              </span>
              <span class="block text-center mt-2">
                {{ __('frontend.delivery_planner.success.delivery_confirmation_information_passed') }}
              </span>
              <span class="block text-center mt-2">
                {{ __('frontend.delivery_planner.success.delivery_confirmation_information_informed') }}
              </span>

              <div class="space-y-2 mt-4">
                <div class="bg-white rounded-md p-4 shadow-md text-sm">
                  <span class="block">
                    <strong>{{ __('frontend.delivery_planner.success.address') }}:</strong> {{ formattedAddress() }}
                  </span>
                  <span
                    v-if="preStop.total_weight"
                    class="block"
                  >
                    <strong>{{ __('frontend.delivery_planner.success.weight') }}:</strong> {{ preStop.total_weight }} kg
                  </span>
                </div>

                <div
                  v-if="preStop.shipment_lines"
                  class="bg-white rounded-md p-4 shadow-md text-sm"
                >
                  <span class="block mb-1 font-bold">
                    {{ __('frontend.delivery_planner.success.goods') }}:
                  </span>
                  <ul class="list-outside list-disc pl-4">
                    <li
                      v-for="shipmentLine in preStop.shipment_lines"
                      :key="shipmentLine"
                    >
                      {{ shipmentLine.quantity }}x {{ shipmentLine.description }}
                    </li>
                  </ul>
                </div>

                <div
                  v-if="preStop.exchange_lines"
                  class="bg-white rounded-md p-4 shadow-md text-sm"
                >
                  <span class="block mb-1 font-bold">
                    {{ __('frontend.delivery_planner.success.exchange') }}:
                  </span>
                  <ul class="list-outside list-disc pl-4">
                    <li
                      v-for="exchangeLine in preStop.exchange_lines"
                      :key="exchangeLine"
                    >
                      {{ exchangeLine.quantity }}x {{ exchangeLine.description }}
                    </li>
                  </ul>
                </div>

                <div class="bg-white rounded-md p-4 shadow-md text-sm">
                  <span class="block mb-1 font-bold">
                    {{ __('frontend.delivery_planner.success.services') }}:
                  </span>
                  <ul class="list-outside list-disc pl-4">
                    <!-- eslint-disable vue/no-v-html -->
                    <li
                      v-for="(formattedDeliveryService, index) in formattedDeliveryServices()"
                      :key="index"
                      v-html="formattedDeliveryService"
                    />
                    <li v-html="formattedDeliveryDate()" />
                    <!-- eslint-enable -->
                  </ul>
                </div>

                <div
                  v-if="formattedLeftToPay()"
                  class="bg-white rounded-md p-4 shadow-md text-sm"
                >
                  <span class="block"><strong>{{ __('frontend.delivery_planner.success.total_amount_to_pay') }}:</strong> {{ formattedLeftToPay() }}</span>
                </div>

                <div
                  v-if="formattedAmountPaid()"
                  class="bg-white rounded-md p-4 shadow-md text-sm"
                >
                  <span class="block"><strong>{{ __('frontend.delivery_planner.success.total_amount_already_paid') }}:</strong> {{ formattedAmountPaid() }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="w-full xl:w-1/3">
          <CompletedSidebar
            :services="formattedDeliveryServices()"
          />
        </div>
      </div>
    </div>
  </Container>
</template>

<script setup>
import {defineProps, provide} from 'vue';
import Container from '@frontend/js/Components/DeliveryPlanner/Container.vue';
import CompletedSidebar from '@frontend/js/Components/DeliveryPlanner/CompletedSidebar.vue';
import {formatMoney} from '@global/js/helpers';
import Decimal from '@agrora/decimal';
import Header from '@frontend/js/Components/DeliveryPlanner/Header.vue';
import moment from 'moment/moment';
import {Head} from '@inertiajs/vue3';

const props = defineProps({
  preStop: {
    type: Object,
    required: true,
  },
});

const formattedAddress = () => {
  let streetWithNumberAndAddition = [props.preStop.street, props.preStop.house_no, props.preStop.address_addition]
    .filter(v => v?.length)
    .join(' ');

  return [streetWithNumberAndAddition, props.preStop.postcode, props.preStop.city]
    .filter(v => v?.length)
    .join(', ');
};

const formattedDeliveryDate = () => {
  let day = moment(props.preStop.eta_from).format('dddd D MMMM');
  day = day.charAt(0).toUpperCase() + day.slice(1);

  const from = moment(props.preStop.eta_from).format('HH:mm');
  const to = moment(props.preStop.eta_to).format('HH:mm');

  const price = Decimal.fromString(props.preStop.cost_date_amount ?? '0.00');

  if (price.isZero()) {
    return `${day}, ${from} - ${to}`;
  }

  return `${day}, ${from} - ${to} (<strong>+ ${formatMoney(price)}</strong>)`;
};

const formattedDeliveryServices = () => {
  const formatted = [];

  if (props.preStop.skip_service_questions || !props.preStop.service_question_answers || props.preStop.service_question_answers.length === 0) {
    return formatted;
  }

  for (const answerId of Object.keys(props.preStop.service_question_answers)) {
    const answer = props.preStop.service_question_answers[answerId];

    if (!answer.answer) {
      continue;
    }

    const price = Decimal.fromString(answer.price ?? '0.00');

    if (answer.type === 'checkbox') {
      let service = `${answer.question}`;

      if (!price.isZero()) {
        service += ` (<strong>+ ${formatMoney(price)}</strong>)`;
      }

      formatted.push(service);
    } else if (answer.type === 'quantity') {
      const amount = parseInt(answer.answer);

      let service = `${amount}x ${answer.question}`;

      if (!price.isZero()) {
        service += ` (<strong>+ ${formatMoney(price)}</strong>)`;
      }

      formatted.push(service);
    }
  }

  return formatted;
};

const formattedLeftToPay = () => {
  const leftToPay = Decimal.fromString(props.preStop.left_to_pay);

  if (leftToPay.isZero()) {
    return null;
  }

  return formatMoney(leftToPay);
};

const formattedAmountPaid = () => {
  const amountPaid = Decimal.fromString(props.preStop.already_paid);

  if (amountPaid.isZero()) {
    return null;
  }

  return formatMoney(amountPaid);
};

provide('preStop', props.preStop);
</script>
